import i18n from 'i18n-js';
import v from 'voca';

import { EMBEDDED_PROJECT_PARAM, ProjectAvailabilities, ProjectStatuses } from '../constants';
import store from '../store';
import { isValidURL } from './urlUtils';

const ProjectAvailabilityLabels = {
  [ProjectAvailabilities.AVAILABLE]: i18n.t('projectAvailabilities.available'),
  [ProjectAvailabilities.UNDER_DEVELOPMENT]: i18n.t('projectAvailabilities.underDevelopment'),
  [ProjectAvailabilities.COMING_SOON]: i18n.t('projectAvailabilities.comingSoon')
};

const ProjectStatusLabels = {
  [ProjectStatuses.DRAFT]: i18n.t('statuses.draft'),
  [ProjectStatuses.PUBLISHED]: i18n.t('statuses.published'),
};

export function getProjectAvailabilityName(availability) {
  return ProjectAvailabilityLabels[availability];
}

export function getProjectStatusName(status) {
  return ProjectStatusLabels[status];
}

export function getProjectEmbeddedBaseUrl(project) {
  if (isValidURL(project.websiteUrl)) return project.websiteUrl;

  const { currentCompany } = store.getState().companies;
  if (isValidURL(currentCompany.embeddedBaseUrl)) return currentCompany.embeddedBaseUrl;

  return currentCompany.domains[0];
}

export function buildProjectDirectLink(project, isEmbedded = false) {
  const { currentCompany } = store.getState().companies;
  const { hashId } = project;

  if (isEmbedded) {
    const baseUrl = getProjectEmbeddedBaseUrl(project);
    if (v.isBlank(baseUrl)) return '';

    const url = new URL(baseUrl);
    url.searchParams.set(EMBEDDED_PROJECT_PARAM, hashId);
    return url.toString();
  }

  const domain = currentCompany.domains[0];
  return new URL(`/homes/build/projects/${hashId}`, domain).toString();
}
