import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import i18n from 'i18n-js';

import { Tabs } from './utils';
import { isDocuSignConnected, isPreApprovalEnabled } from '../../../../../utils/companyUtils';
import { isAdmin } from '../../../../../utils/authUtils';
import Overview from './Overview';
import Packages from './Packages';
import Palettes from './Palettes';
import SalesRepresentatives from './SalesRepresentatives';
import MortgageProviders from './MortgageProviders';
import ReservationDocument from './ReservationDocument';
import Modifications from '../../../../global/Modifications';

const i18nOpts = { scope: 'components.admin.projects.show.sections.index' };

const Sections = ({ project }) => {
  const [activeTab, setActiveTab] = useState(Tabs.OVERVIEW);

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            active={activeTab === Tabs.OVERVIEW}
            onClick={() => setActiveTab(Tabs.OVERVIEW)}
          >
            {i18n.t('tabs.overview', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === Tabs.PACKAGES}
            onClick={() => setActiveTab(Tabs.PACKAGES)}
          >
            {i18n.t('tabs.packages', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === Tabs.PALETTES}
            onClick={() => setActiveTab(Tabs.PALETTES)}
          >
            {i18n.t('tabs.palettes', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === Tabs.SALES_REPRESENTATIVES}
            onClick={() => setActiveTab(Tabs.SALES_REPRESENTATIVES)}
          >
            {i18n.t('tabs.salesRepresentatives', i18nOpts)}
          </NavLink>
        </NavItem>
        {isPreApprovalEnabled() && (
          <NavItem>
            <NavLink
              active={activeTab === Tabs.MORTGAGE_PROVIDERS}
              onClick={() => setActiveTab(Tabs.MORTGAGE_PROVIDERS)}
            >
              {i18n.t('tabs.mortgageProviders', i18nOpts)}
            </NavLink>
          </NavItem>
        )}
        {isDocuSignConnected() && (
          <NavItem>
            <NavLink
              active={activeTab === Tabs.RESERVATION_DOCUMENT}
              onClick={() => setActiveTab(Tabs.RESERVATION_DOCUMENT)}
            >
              {i18n.t('tabs.reservationDocument', i18nOpts)}
            </NavLink>
          </NavItem>
        )}
        {isAdmin() && (
          <NavItem>
            <NavLink
              active={activeTab === Tabs.MODIFICATIONS}
              onClick={() => setActiveTab(Tabs.MODIFICATIONS)}
            >
              {i18n.t('tabs.modifications', i18nOpts)}
            </NavLink>
          </NavItem>
        )}
      </Nav>

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.OVERVIEW}>
          <Overview />
        </TabPane>
        <TabPane tabId={Tabs.PACKAGES}>
          <Packages />
        </TabPane>
        <TabPane tabId={Tabs.PALETTES}>
          <Palettes />
        </TabPane>
        <TabPane tabId={Tabs.SALES_REPRESENTATIVES}>
          <SalesRepresentatives />
        </TabPane>
        {isPreApprovalEnabled() && (
          <TabPane tabId={Tabs.MORTGAGE_PROVIDERS}>
            <MortgageProviders />
          </TabPane>
        )}
        {isDocuSignConnected() && (
          <TabPane tabId={Tabs.RESERVATION_DOCUMENT}>
            <ReservationDocument />
          </TabPane>
        )}
        {isAdmin() && (
          <TabPane tabId={Tabs.MODIFICATIONS}>
            <Modifications instance={project} />
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

Sections.propTypes = {};

Sections.defaultProps = {};

export default connect((store) => ({
  project: store.projects.project,
}))(Sections);
