import React from 'react';
import {
  Col, FormGroup, Input, Label, Row
} from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import v from 'voca';

import { isMasterAdmin } from '../../../../../../utils/authUtils';
import { CompanyContractStages, CompanyContractTypes } from '../../../../../../constants';
import DatePicker from '../../../../../common/DatePicker';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.contract.index' };

const Contract = ({ form, error, onTextChange }) => {
  const contract = form.contract || {};

  const onChangeContractSetting = (event) => {
    const { name, value } = event.target;

    const key = v.camelCase(name.replace(/^contract/, ''));
    const newContract = { ...contract, [key]: value };

    onTextChange({ target: { name: 'contract', value: newContract } });
  };

  const onChangeDate = (name, newDate) => {
    onChangeContractSetting({ target: { name, value: newDate } });
  };

  if (!isMasterAdmin()) return null;

  return (
    <>
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row>
            <Label sm={5}>{i18n.t('startDate', i18nOpts)}</Label>
            <Col sm={7}>
              <DatePicker
                initialDate={contract.startDate}
                onChange={(date) => onChangeDate('contractStartDate', date)}
              />
            </Col>
          </FormGroup>

          <FormGroup row id="contractRenewalDateContainer">
            <Label sm={5}>{i18n.t('renewalDate', i18nOpts)}</Label>
            <Col sm={7}>
              <DatePicker
                initialDate={contract.renewalDate}
                onChange={(date) => onChangeDate('contractRenewalDate', date)}
              />
            </Col>
          </FormGroup>

          <FormGroup row id="contractMonthlyAmountContainer">
            <Label sm={5}>{i18n.t('monthlyAmount', i18nOpts)}</Label>
            <Col sm={7}>
              <div className="input-icon-prepend font-16" style={{ bottom: 'unset' }}>$</div>
              <Input
                type="number"
                name="contractMonthlyAmount"
                id="contractMonthlyAmount"
                value={contract.monthlyAmount || ''}
                onChange={onChangeContractSetting}
              />
            </Col>
          </FormGroup>

          <FormGroup row id="contractTypeContainer">
            <Label sm={5}>{i18n.t('type', i18nOpts)}</Label>
            <Col sm={7}>
              <Input
                type="select"
                name="contractType"
                id="contractType"
                value={contract.type || ''}
                onChange={onChangeContractSetting}
              >
                <option value="">{i18n.t('select.select')}</option>
                {
                  Object.values(CompanyContractTypes).map((cct) => (
                    <option key={`contract-type-${cct}`} value={cct}>
                      {i18n.t(`contractTypes.${v.camelCase(cct)}`, i18nOpts)}
                    </option>
                  ))
                }
              </Input>
            </Col>
          </FormGroup>

          <FormGroup row id="contractStageContainer">
            <Label sm={5}>{i18n.t('stage', i18nOpts)}</Label>
            <Col sm={7}>
              <Input
                type="select"
                name="contractStage"
                id="contractStage"
                value={contract.stage || ''}
                onChange={onChangeContractSetting}
              >
                <option value="">{i18n.t('select.select')}</option>
                {
                  Object.values(CompanyContractStages).map((ccs) => (
                    <option key={`contract-stage-${ccs}`} value={ccs}>
                      {i18n.t(`contractStages.${v.camelCase(ccs)}`, i18nOpts)}
                    </option>
                  ))
                }
              </Input>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

Contract.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.any),
  onTextChange: PropTypes.func
};

Contract.defaultProps = {
  error: null,
  onTextChange: () => {}
};

export default Contract;
