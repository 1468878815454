import React, { Component } from 'react';
import {
  ListGroup, ListGroupItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu
} from 'reactstrap';
import { BiGridVertical, BiTrash } from 'react-icons/bi';
import { IoPersonCircleOutline, IoSettingsSharp } from 'react-icons/io5';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { ReactSortable } from 'react-sortablejs';
import classnames from 'classnames';
import { Link } from 'gatsby';

import api from '../../../../../../api';
import { listProductTypesQuery } from '../../../../../../graphql';
import { DEFAULT_IMAGE, ModalNames, NavigationMainNames } from '../../../../../../constants';
import {
  getCommunityProductTypes,
  saveCommunityProductType,
  deleteCommunityProductType,
  sortCommunityProductTypes,
  setCommunityProductTypes
} from '../../../../../../store/actions/communityProductTypeActions';
import SalesRepresentativesModal from './SalesRepresentativesModal';
import ProductTypeSettingsModal from './ProductTypeSettingsModal';
import ModalService from '../../../../../../services/ModalService';
import { isAdmin } from '../../../../../../utils/authUtils';
import { confirmDelete } from '../../../../../global/confirmAlert';
import NavigationService from '../../../../../../services/NavigationService';
import './styles.scss';

const i18nOpts = { scope: 'components.admin.communities.show.sections.productTypes.index' };

function getProductTypesAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(listProductTypesQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

function onOpenModal(modalName, communityProductType) {
  ModalService.open(modalName, { communityProductType });
}

function hasSalesRep(communityProductType) {
  const { salesRepresentatives } = communityProductType;
  return salesRepresentatives.length > 0;
}

class ProductTypes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productTypes: [],
      isDropdownOpen: false
    };

    this.onToggleDropdown = this.onToggleDropdown.bind(this);
    this.onAddProductType = this.onAddProductType.bind(this);
    this.onRemoveCommunityProductType = this.onRemoveCommunityProductType.bind(this);
    this.onSetProductTypes = this.onSetProductTypes.bind(this);
    this.onSortProductTypes = this.onSortProductTypes.bind(this);
  }

  componentDidMount() {
    this.loadProductTypes();
    this.loadCommunityProductTypes();
  }

  onToggleDropdown() {
    this.setState(({ isDropdownOpen }) => ({ isDropdownOpen: !isDropdownOpen }));
  }

  onAddProductType(productType) {
    const { props } = this;
    const { community } = this.props;

    const variables = { input: { communityId: community.id, productTypeId: productType.id } };
    props.saveCommunityProductType(variables);
  }

  onRemoveCommunityProductType(communityProductType) {
    const { props } = this;

    props.deleteCommunityProductType(communityProductType.id);
  }

  onSetProductTypes(communityProductTypes) {
    const { props } = this;

    props.setCommunityProductTypes(communityProductTypes);
  }

  onSortProductTypes() {
    const { props } = this;
    const { community, communityProductTypes } = this.props;

    const ids = communityProductTypes.map((cpt) => cpt.id);

    const variables = { communityId: community.id, ids };
    props.sortCommunityProductTypes(variables);
  }

  getAvailableProductTypes() {
    const { productTypes } = this.state;
    const { communityProductTypes } = this.props;
    const selectedProductTypeIds = communityProductTypes.map((cpt) => cpt.productType.id);
    return productTypes.filter((pt) => !selectedProductTypeIds.includes(pt.id));
  }

  getProductTypeUrl(communityProductType) {
    return NavigationService.getNavigationShowUrl(
      communityProductType.productType, NavigationMainNames.PRODUCT_TYPES
    );
  }

  loadProductTypes() {
    const { currentCompany } = this.props;
    getProductTypesAsync(currentCompany.id)
      .then((productTypes) => {
        this.setState({ productTypes });
      })
      .catch(() => navigate('/admin/overview'));
  }

  loadCommunityProductTypes() {
    const { props } = this;
    const { community } = this.props;

    const variables = { filter: { communityId: community.id } };
    props.getCommunityProductTypes(variables);
  }

  render() {
    const { isDropdownOpen } = this.state;
    const { communityProductTypes } = this.props;

    const availableProductTypes = this.getAvailableProductTypes();

    return (
      <div>
        {isAdmin() && (
          <div className="text-right mb-3">
            <Dropdown isOpen={isDropdownOpen} toggle={this.onToggleDropdown}>
              <DropdownToggle color="outline-secondary" caret disabled={!availableProductTypes.length}>
                {i18n.t('buttons.add', i18nOpts)}
              </DropdownToggle>
              <DropdownMenu right>
                {
                  availableProductTypes.map((productType) => (
                    <DropdownItem
                      key={`available-product-type-${productType.id}`}
                      onClick={() => this.onAddProductType(productType)}
                    >
                      {productType.name}
                    </DropdownItem>
                  ))
                }
              </DropdownMenu>
            </Dropdown>
          </div>
        )}

        <ListGroup>
          <ReactSortable
            list={communityProductTypes}
            handle=".sortable-product-type-handler"
            draggable=".sortable-product-type-item"
            setList={this.onSetProductTypes}
            onEnd={this.onSortProductTypes}
          >
            {
              communityProductTypes.map((communityProductType) => (
                <ListGroupItem
                  key={`community-product-type-${communityProductType.id}`}
                  className="community-product-type-container sortable-product-type-item"
                >
                  <div className="d-flex align-items-center">
                    <div className="mr-3">
                      <Link to={this.getProductTypeUrl(communityProductType)}>
                        <img
                          className="community-product-type-image"
                          src={communityProductType.productType.imageUrl || DEFAULT_IMAGE}
                          alt={communityProductType.productType.name}
                        />
                      </Link>
                    </div>
                    <div className="flex-grow-1">
                      <Link to={this.getProductTypeUrl(communityProductType)}>
                        {communityProductType.productType.name}
                      </Link>
                    </div>

                    {isAdmin() && (
                      <div>
                        <IoSettingsSharp
                          className="mr-3"
                          role="button"
                          onClick={() => onOpenModal(
                            ModalNames.PRODUCT_TYPE_SETTINGS,
                            communityProductType
                          )}
                          title={i18n.t('buttons.productTypeSettings', i18nOpts)}
                          size="1rem"
                        />

                        <span
                          role="button"
                          aria-hidden
                          className={classnames('mr-3', { 'text-success': hasSalesRep(communityProductType) })}
                          onClick={() => onOpenModal(
                            ModalNames.PRODUCT_TYPE_SALES_REPRESENTATIVES,
                            communityProductType
                          )}
                          title={i18n.t('buttons.salesRep', i18nOpts)}
                        >
                          <IoPersonCircleOutline size="1.1rem" />
                        </span>

                        <BiGridVertical
                          size="1.2rem"
                          className="sortable-product-type-handler mr-3"
                          title={i18n.t('buttons.sort')}
                        />

                        <span
                          role="button"
                          aria-hidden
                          className="text-danger"
                          onClick={
                            // eslint-disable-next-line max-len
                            () => confirmDelete(() => this.onRemoveCommunityProductType(communityProductType))
                          }
                          title={i18n.t('buttons.delete')}
                        >
                          <BiTrash size="1.1rem" />
                        </span>
                      </div>
                    )}
                  </div>
                </ListGroupItem>
              ))
            }
          </ReactSortable>
        </ListGroup>

        <SalesRepresentativesModal />
        <ProductTypeSettingsModal />
      </div>
    );
  }
}

export default connect((store) => ({
  community: store.communities.community,
  communityProductTypes: store.communityProductTypes.communityProductTypes,
  currentCompany: store.companies.currentCompany
}), {
  getCommunityProductTypes,
  saveCommunityProductType,
  deleteCommunityProductType,
  setCommunityProductTypes,
  sortCommunityProductTypes
})(ProductTypes);
