import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

import TopBar from './TopBar';
import Sidebar from './Sidebar';
import Messages from '../Messages';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/style.css';
import '../../assets/css/common.scss';
import '../../assets/css/custom.scss';
import '../../assets/css/global.scss';
import '../../assets/css/custom/daterangepicker.scss';
import './styles.scss';
import NavigationService from '../../services/NavigationService';
import { toggleSidebarCollapse } from './utils';
import { isMobile } from '../../utils/windowUtils';
import SecureStore from '../../utils/secureStore';
import SalesInvitationModal from '../../components/global/SalesInvitationModal';

const Layout = (props) => {
  const { pathname } = useLocation();

  const updateDimensions = () => {
    toggleSidebarCollapse(isMobile());
  };

  const loadBodyClassNames = () => {
    if (isMobile()) document.body.classList.add('sidebar-collapse', 'sidebar-closed');
    else {
      const isSidebarExpanded = SecureStore.isSidebarExpanded();
      if (!isSidebarExpanded) document.body.classList.add('mini-sidebar');
    }

    setTimeout(() => {
      const pageWrapper = document.getElementsByClassName('page-wrapper')[0];
      if (pageWrapper) pageWrapper.style.transition = '.2s ease-in';
    }, 200);
  };

  useEffect(() => {
    loadBodyClassNames();

    window.addEventListener('load', updateDimensions.bind(null));
    window.addEventListener('resize', updateDimensions.bind(null));
    return () => {
      window.removeEventListener('load', updateDimensions.bind(null));
      window.removeEventListener('resize', updateDimensions.bind(null));
    };
  }, []);

  useEffect(() => {
    NavigationService.resetIfNecessary(pathname);
  }, [pathname]);

  return (
    <>
      <Helmet bodyAttributes={{ id: 'admin-container' }}>
        {/* Animate CSS */}
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css" />
      </Helmet>

      <Messages />

      <div id="main-wrapper">
        <TopBar />

        <Sidebar {...props} />

        <div className="page-wrapper d-block">
          <div className="page-content container-fluid">
            {props.children}
          </div>
        </div>
      </div>

      <SalesInvitationModal />
    </>
  );
};

export default Layout;
