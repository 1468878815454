import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import momentTz from 'moment-timezone';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import styles from './DashboardMetrics.module.scss';
import MetricCard from './MetricCard';
import { fetchDashboardMetrics } from './utils';
import useIsReserveNowEnabled from '../../../../hooks/useIsReserveNowEnabled';
import useIsVerifiedEnabled from '../../../../hooks/useIsVerifiedEnabled';

const i18nOpts = { scope: 'components.admin.overview.dashboardMetrics.index' };

const DashboardMetrics = ({ currentCompany, customDateRange, previousCustomDateRange }) => {
  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState({});

  const isReserveNowEnabled = useIsReserveNowEnabled();
  const isVerifiedEnabled = useIsVerifiedEnabled();

  const loadMetrics = () => {
    setLoading(true);

    const timezone = momentTz.tz.guess();
    const filter = {
      companyId: currentCompany.id,
      timezone,
      customDateRange,
      previousCustomDateRange
    };
    const variables = { reportFilter: filter };
    fetchDashboardMetrics(variables)
      .then((result) => {
        setMetrics(result);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadMetrics();
  }, [customDateRange]);

  return (
    <Row className="align-items-stretch">
      {loading ? (
        <Col><div className="text-center my-5"><Spinner size="lg" /></div></Col>
      ) : (
        <>
          <Col xs="12" sm="6" md="4" xl className={styles.col}>
            <MetricCard
              title={i18n.t('visitors', i18nOpts)}
              value={metrics.visitors?.currentValue}
              percentageChange={metrics.visitors?.percentageChange}
            />
          </Col>
          <Col xs="12" sm="6" md="4" xl className={styles.col}>
            <MetricCard
              title={i18n.t('returningVisitors', i18nOpts)}
              value={metrics.returningVisitors?.currentValue}
              percentageChange={metrics.returningVisitors?.percentageChange}
            />
          </Col>
          <Col xs="12" sm="6" md="4" xl className={styles.col}>
            <MetricCard
              title={i18n.t('newLeads', i18nOpts)}
              value={metrics.newLeads?.currentValue}
              percentageChange={metrics.newLeads?.percentageChange}
            />
          </Col>
          <Col xs="12" sm="6" md="4" xl className={styles.col}>
            <MetricCard
              title={i18n.t('homesSaved', i18nOpts)}
              value={metrics.homesSaved?.currentValue}
              percentageChange={metrics.homesSaved?.percentageChange}
            />
          </Col>
          {isReserveNowEnabled && (
            <Col xs="12" sm="6" md="4" xl className={styles.col}>
              <MetricCard
                title={i18n.t('reservations', i18nOpts)}
                value={metrics.reservations?.currentValue}
                percentageChange={metrics.reservations?.percentageChange}
              />
            </Col>
          )}
          {isVerifiedEnabled && (
            <Col xs="12" sm="6" md="4" xl className={styles.col}>
              <MetricCard
                title={i18n.t('verifications', i18nOpts)}
                value={metrics.verifications?.currentValue}
                percentageChange={metrics.verifications?.percentageChange}
              />
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

DashboardMetrics.propTypes = {
  customDateRange: PropTypes.objectOf(PropTypes.any).isRequired,
  previousCustomDateRange: PropTypes.objectOf(PropTypes.any).isRequired
};

DashboardMetrics.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(DashboardMetrics);
