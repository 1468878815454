import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import api from '../../../../../../api';
import ListItem from './ListItem';
import AddDropdown from './AddDropdown';
import { listProjectPalettesQuery } from '../../../../../../graphql';
import { reloadProjectPalettes } from '../../../../../../store/actions/projectPaletteActions';
import { isAdmin } from '../../../../../../utils/authUtils';

function fetchProjectPalettesAsync(project) {
  const variables = { filter: { projectId: project.id } };

  return api.graphql(listProjectPalettesQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

function fetchPalettesAsync(companyId) {
  const variables = { filter: { companyId, isMultifamily: true } };

  return api.graphql(`
    query ListPalettes($filter: PaletteFilterInput) {
      items: listPalettes(filter: $filter) {
        id
        name
      }
    }
  `, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

const Palettes = ({
  project, reload, currentCompany, ...props
}) => {
  const [projectPalettes, setProjectPalettes] = useState([]);
  const [palettes, setPalettes] = useState([]);

  useEffect(() => {
    loadProjectPalettes();
    loadPalettes();
  }, []);

  useEffect(() => {
    if (!reload) return;

    loadProjectPalettes();
  }, [reload]);

  const loadProjectPalettes = () => {
    fetchProjectPalettesAsync(project)
      .then((items) => {
        setProjectPalettes(items);
        props.reloadProjectPalettes(false);
      })
      .catch(() => {});
  };

  const loadPalettes = () => {
    fetchPalettesAsync(currentCompany.id)
      .then((items) => setPalettes(items))
      .catch(() => {});
  };

  const loadAvailablePalettes = () => palettes.filter(
    (p) => !projectPalettes.some((pp) => pp.palette.id === p.id)
  );

  const availablePalettes = loadAvailablePalettes();

  if (!project) return null;

  return (
    <div>
      {isAdmin() && (
        <div className="d-flex justify-content-end mb-3">
          <AddDropdown availablePalettes={availablePalettes} project={project} />
        </div>
      )}

      {
        projectPalettes.map((pp) => (
          <ListItem projectPalette={pp} key={`project-palette-${pp.id}`} />
        ))
      }
    </div>
  );
};

export default connect((store) => ({
  project: store.projects.project,
  reload: store.projectPalettes.reload,
  currentCompany: store.companies.currentCompany
}), {
  reloadProjectPalettes
})(Palettes);
