import React, { useState, useEffect, useCallback } from 'react';
import { Card, Spinner } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import './styles.scss';
import { formatRange } from '../../../../utils/dateUtils';
import ActionsMenu from '../components/ActionsMenu';
import { exportToCSV } from '../utils';
import { fetchLeadGenerationDataAsync } from './utils';

const i18nOpts = { scope: 'components.admin.overview.leadGeneration.index' };

const KEYS = ['ownlyLeadsCount', 'webtrackerLeadsCount'];
const humanizedCategoriesPath = ['ownlyLeadsCaptured', 'webtrackerLeadsCaptured'];

function getChartData(leadGeneration) {
  return {
    labels: leadGeneration.map((c) => c.dateDivision.toUpperCase()),
    datasets: [
      {
        label: i18n.t('ownlyLeadsCaptured', i18nOpts).toUpperCase(),
        backgroundColor: '#4D4D4D',
        borderWidth: 0,
        hoverBackgroundColor: '#4D4D4D',
        data: leadGeneration.map((c) => c.capturedLeads.ownlyLeadsCount),
        stack: 'leadsCaptured'
      },
      {
        label: i18n.t('webtrackerLeadsCaptured', i18nOpts).toUpperCase(),
        backgroundColor: '#ABABAB',
        borderWidth: 0,
        hoverBackgroundColor: '#ABABAB',
        data: leadGeneration.map((c) => c.capturedLeads.webtrackerLeadsCount),
        stack: 'leadsCaptured'
      }
    ]
  };
}

function getMappedTotalCount(items) {
  return items.reduce((prev, current) => ({
    [KEYS[0]]: prev[KEYS[0]] + current.capturedLeads.ownlyLeadsCount,
    [KEYS[1]]: prev[KEYS[1]] + current.capturedLeads.webtrackerLeadsCount
  }), ({
    [KEYS[0]]: 0,
    [KEYS[1]]: 0
  }));
}

const LeadGeneration = ({ filter, currentCompany }) => {
  const [loading, setLoading] = useState(false);
  const [isLegendHover, setIsLegendHover] = useState(false);
  const [leadGeneration, setLeadGeneration] = useState([]);
  const [totalCountPerCategory, setTotalCountPerCategory] = useState({});
  const [dateRangeLabel, setDateRangeLabel] = useState('');
  const [tooltipText, setTooltipText] = useState(null);
  const [tooltipProperties, setTooltipProperties] = useState({});

  const loadData = () => {
    setLoading(true);

    const rangeDateLabel = formatRange(filter?.customDateRange);
    setDateRangeLabel(rangeDateLabel);

    fetchLeadGenerationDataAsync(filter, currentCompany)
      .then((items) => {
        const totals = getMappedTotalCount(items);
        setTotalCountPerCategory(totals);
        setLeadGeneration(items);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!filter) return;

    loadData();
  }, [filter]);

  const getLegend = useCallback(() => ({
    align: 'end',
    labels: {
      color: '#000000',
      font: {
        weight: '500',
        size: 10,
      },
      fontSize: 10,
      boxWidth: 10,
    },
    onHover: (event, item) => {
      if (isLegendHover) return;
      const { datasetIndex } = item;
      const categoryHumanized = i18n.t(humanizedCategoriesPath[datasetIndex], i18nOpts);
      const text = i18n.t('totalPerCategory', {
        ...i18nOpts,
        count: totalCountPerCategory[KEYS[datasetIndex]],
        category: categoryHumanized
      });
      setIsLegendHover(true);
      setTooltipProperties({ x: event.x, y: event.y, fillStyle: item.fillStyle });
      setTooltipText(text);
    },
    onLeave: () => {
      setIsLegendHover(false);
    }
  }), [isLegendHover, totalCountPerCategory]);

  const data = getChartData(leadGeneration);

  const getOptions = useCallback(() => ({
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            color: '#000000',
            weight: '800',
            size: 10,
          },
          autoSkip: true,
          autoSkipPadding: 10,
          maxRotation: 0,
          minRotation: 0
        },
      },
      y: {
        stacked: true,
        ticks: {
          fontColor: '#000000',
          font: {
            weight: '800',
            size: 10,
          },
          stepSize: 1
        }
      },
    },
    plugins: {
      legend: getLegend(),
    },
    responsive: true,
    maintainAspectRatio: false
  }), [getLegend]);

  const onExport = useCallback(() => {
    const headers = [
      i18n.t('date', i18nOpts),
      i18n.t('ownlyLeadsCaptured', i18nOpts),
      i18n.t('webtrackerLeadsCaptured', i18nOpts)
    ];
    const rows = leadGeneration.map((item) => [
      item.dateDivision,
      item.capturedLeads.ownlyLeadsCount,
      item.capturedLeads.webtrackerLeadsCount
    ]);
    exportToCSV(headers, rows, `Lead Generation ${dateRangeLabel}.csv`);
  }, [leadGeneration, dateRangeLabel]);

  return (
    <Card body className="h-100">
      <div className="d-flex align-items-center">
        <h4>{i18n.t('title', i18nOpts)}</h4>
        <div className="ml-auto">
          <ActionsMenu onExport={onExport} />
        </div>
      </div>

      <div className="text-uppercase text-right mb-3">
        <small>{dateRangeLabel}</small>
      </div>

      {loading ? (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      ) : (
        <div style={{ height: '275px' }}>
          <Bar data={data} options={getOptions()} />
        </div>
      )}

      {isLegendHover && (
        <div
          id="lead-generation-legend-tooltip"
          className="d-flex align-items-center"
          style={{
            top: `calc(${tooltipProperties.y}px + 3.8vh)`,
            left: `calc(${tooltipProperties.x}px - 4vh)`
          }}
        >
          <span
            className="fill-demo mr-1 pt-1"
            style={{ background: tooltipProperties.fillStyle }}
          />
          <span>{tooltipText}</span>
        </div>
      )}
    </Card>
  );
};

LeadGeneration.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any)
};

LeadGeneration.defaultProps = {
  filter: null
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {})(LeadGeneration);
