import React from 'react';

import BackLink from '../components/BackLink';
import ActionsMenu from '../components/ActionsMenu';
import Details from './Details';

const Summary = () => (
  <div className="p-3">
    <div className="d-flex align-items-center justify-content-between mb-3">
      <BackLink />

      <ActionsMenu />
    </div>

    <Details />
  </div>
);

Summary.propTypes = {};

Summary.defaultProps = {};

export default Summary;
