import React from 'react';
import i18n from 'i18n-js';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';

import { formatTimestamp } from '../../../../../utils/dateUtils';
import ListEmpty from '../../../../common/ListEmpty';

const i18nOpts = { scope: 'components.admin.quickPossessions.show.followers.index' };

const Followers = ({ followers }) => (
  <div>
    { followers && followers.length === 0 && (
      <ListEmpty items={followers} />
    )}

    { followers && followers.length > 0 && (
      <Table responsive bordered>
        <thead>
          <tr>
            <th>{i18n.t('name', i18nOpts)}</th>
            <th>{i18n.t('email', i18nOpts)}</th>
            <th>{i18n.t('phone', i18nOpts)}</th>
            <th>{i18n.t('requestDate', i18nOpts)}</th>
          </tr>
        </thead>
        <tbody>
          { followers?.map((request) => (
            <tr key={`home-request-${request.id}`}>
              <td>{request?.contact.name}</td>
              <td>{request?.contact.email}</td>
              <td>{request?.contact.phone}</td>
              <td>{formatTimestamp(request?.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )}
  </div>
);

Followers.propTypes = {
  followers: PropTypes.arrayOf(PropTypes.object).isRequired
};

Followers.defaultProps = {};

export default Followers;
