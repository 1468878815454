import v from 'voca';

import { UnitStages } from '../../../../constants';
import api from '../../../../api';
import { listUnitsQuery } from '../../../../graphql';

export const Tabs = Object.freeze({
  ACTIVE_LISTINGS: 'activeListings',
  INACTIVE_LISTINGS: 'inactiveListings'
});

export function getUnitFilters(currentCompany, filters, query, showOnlyScheduledToArchive) {
  const filter = { companyId: currentCompany.id };

  filter.stage = showOnlyScheduledToArchive
    ? UnitStages.SCHEDULED_TO_ARCHIVE
    : UnitStages.ACTIVE;

  if (!v.isBlank(query)) filter.query = query;

  if (filters.projects?.length) {
    filter.projectIds = filters.projects.map((project) => project.id);
  }

  if (filters.buildingModels?.length) {
    filter.buildingModelIds = filters.buildingModels.map((buildingModel) => buildingModel.id);
  }

  if (filters.bedrooms?.length) filter.bedroomsValues = filters.bedrooms;

  if (filters.bathrooms?.length) filter.bathroomsValues = filters.bathrooms;

  if (filters.availabilities?.length) filter.availabilities = filters.availabilities;

  return filter;
}

export function fetchUnitsAsync(variables) {
  return api.graphql(listUnitsQuery, variables)
    .then(({ data: { result } }) => result);
}
