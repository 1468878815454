import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import ListEmpty from '../../../../../common/ListEmpty';
import FollowersList from '../components/FollowersList';

const i18nOpts = { scope: 'components.admin.units.show.followers.savedHomes.index' };

const SavedHomes = ({ followers }) => (
  <div>
    <h4>{i18n.t('title', i18nOpts)}</h4>

    <ListEmpty items={followers} />

    {followers && followers.length > 0 && (
      <FollowersList followers={followers} />
    )}
  </div>
);

SavedHomes.propTypes = {
  followers: PropTypes.arrayOf(PropTypes.object).isRequired
};

SavedHomes.defaultProps = {};

export default SavedHomes;
