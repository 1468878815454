import React from 'react';
import { DropdownItem } from 'reactstrap';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import BaseActionsMenu from '../../../../../global/ActionsMenu';
import { deleteUnit, setReloadUnits } from '../../../../../../store/actions/unitActions';
import useIsReserveNowEnabled from '../../../../../../hooks/useIsReserveNowEnabled';
import ModalService from '../../../../../../services/ModalService';
import { ModalNames, SalesInvitationStartTypes } from '../../../../../../constants';

const ActionsMenu = ({ unit, ...props }) => {
  const isReserveNowEnabled = useIsReserveNowEnabled();

  const onEdit = () => {
    navigate(`/admin/units/form?id=${unit.id}`);
  };

  const onDelete = () => {
    props.deleteUnit(unit.id)
      .then(() => props.setReloadUnits(true));
  };

  const onInvite = () => {
    ModalService.open(ModalNames.SALES_INVITATION, {
      startType: SalesInvitationStartTypes.FROM_HOME,
      home: { ...unit, __typename: 'Unit' }
    });
  };

  return (
    <BaseActionsMenu
      item={unit}
      onEdit={onEdit}
      onDelete={onDelete}
    >
      {isReserveNowEnabled && (
        <DropdownItem onClick={onInvite}>
          {i18n.t('buttons.invite')}
        </DropdownItem>
      )}
    </BaseActionsMenu>
  );
};

ActionsMenu.propTypes = {
  unit: PropTypes.objectOf(PropTypes.any).isRequired
};

ActionsMenu.defaultProps = {};

export default connect(() => ({
}), {
  deleteUnit,
  setReloadUnits
})(ActionsMenu);
