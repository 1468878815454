import React from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';

import ListHeader from './ListHeader';
import ListItem from './ListItem';

const ListView = ({ quickPossessions }) => {
  if (!quickPossessions.length) return null;

  return (
    <Table responsive bordered id="quick-possessions-table">
      <thead>
        <ListHeader />
      </thead>
      <tbody>
        {
          quickPossessions.map((quickPossession) => (
            <ListItem key={`quick-possession-${quickPossession.id}`} quickPossession={quickPossession} />
          ))
        }
      </tbody>
    </Table>
  );
};

ListView.propTypes = {
  quickPossessions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
};

ListView.defaultProps = {
  quickPossessions: []
};

export default ListView;
