import {
  CLEAN_COMMUNITIES, GET_COMMUNITIES, SAVE_COMMUNITY, GET_COMMUNITY, DELETE_COMMUNITY,
  SET_COMMUNITY_SEARCH_FILTERS, SET_COMMUNITIES_VIEW_MODE, SET_COMMUNITY_ATTRIBUTES
} from './types';
import {
  createCommunityQuery,
  deleteCommunityQuery,
  getCommunityQuery,
  listCommunitiesQuery,
  updateCommunityQuery
} from '../../graphql';
import api from '../../api';

export function getCommunities(variables = {}) {
  return {
    type: GET_COMMUNITIES,
    payload: api.graphql(listCommunitiesQuery, variables)
  };
}

export function getCommunity(id) {
  return {
    type: GET_COMMUNITY,
    payload: api.graphql(getCommunityQuery, { id })
  };
}

export function cleanCommunities(extraProps = {}) {
  return {
    type: CLEAN_COMMUNITIES,
    payload: Promise.resolve(extraProps)
  };
}

export function setCommunitySearchFilters(searchFilters) {
  return {
    type: SET_COMMUNITY_SEARCH_FILTERS,
    payload: Promise.resolve({ searchFilters })
  };
}

export function saveCommunity(variables = {}) {
  const query = variables.input.id ? updateCommunityQuery : createCommunityQuery;
  return {
    type: SAVE_COMMUNITY,
    payload: api.graphql(query, variables)
  };
}

export function deleteCommunity(id) {
  return {
    type: DELETE_COMMUNITY,
    payload: api.graphql(deleteCommunityQuery, { id })
  };
}

export function setCommunitiesViewMode(viewMode) {
  return {
    type: SET_COMMUNITIES_VIEW_MODE,
    payload: { viewMode }
  };
}

export function setCommunityAttributes(communityId, attributes = {}) {
  return {
    type: SET_COMMUNITY_ATTRIBUTES,
    payload: { communityId, attributes }
  };
}
