import React from 'react';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../../../../../../../../utils/currencyUtils';

const Price = ({ unit }) => (
  <div className="font-weight-500 col-gray-900">
    {formatCurrency(unit.price)}
  </div>
);

Price.propTypes = {
  unit: PropTypes.objectOf(PropTypes.any).isRequired
};

Price.defaultProps = {};

export default Price;
