import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import i18n from 'i18n-js';

import { Tabs } from './utils';
import { isDocuSignConnected, isPreApprovalEnabled } from '../../../../../utils/companyUtils';
import { isAdmin } from '../../../../../utils/authUtils';
import Overview from './Overview';
import ProductTypes from './ProductTypes';
import Models from './Models';
import Phases from './Phases';
import Images from './Images';
import SalesRepresentatives from './SalesRepresentatives';
import MortgageProviders from './MortgageProviders';
import ReservationDocument from './ReservationDocument';
import Modifications from '../../../../global/Modifications';
import BackLink from '../components/BackLink';
import DetailsButton from '../components/DetailsButton';

const i18nOpts = { scope: 'components.admin.communities.show.sections.index' };

const Sections = ({ community }) => {
  const [activeTab, setActiveTab] = useState(Tabs.OVERVIEW);

  return (
    <div>
      <div className="d-block d-md-none">
        <BackLink />

        <div className="d-flex align-items-center gap-3">
          <h2 className="mb-0">{community.name}</h2>
          <div className="ml-auto">
            <DetailsButton />
          </div>
        </div>
      </div>

      <Nav tabs>
        <NavItem>
          <NavLink
            active={activeTab === Tabs.OVERVIEW}
            onClick={() => setActiveTab(Tabs.OVERVIEW)}
          >
            {i18n.t('tabs.overview', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === Tabs.PRODUCT_TYPES}
            onClick={() => setActiveTab(Tabs.PRODUCT_TYPES)}
          >
            {i18n.t('tabs.productTypes', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === Tabs.MODELS}
            onClick={() => setActiveTab(Tabs.MODELS)}
          >
            {i18n.t('tabs.models', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === Tabs.PHASES}
            onClick={() => setActiveTab(Tabs.PHASES)}
          >
            {i18n.t('tabs.phases', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === Tabs.IMAGES}
            onClick={() => setActiveTab(Tabs.IMAGES)}
          >
            {i18n.t('tabs.images', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === Tabs.SALES_REPRESENTATIVES}
            onClick={() => setActiveTab(Tabs.SALES_REPRESENTATIVES)}
          >
            {i18n.t('tabs.salesRepresentatives', i18nOpts)}
          </NavLink>
        </NavItem>
        {isPreApprovalEnabled() && (
          <NavItem>
            <NavLink
              active={activeTab === Tabs.MORTGAGE_PROVIDERS}
              onClick={() => setActiveTab(Tabs.MORTGAGE_PROVIDERS)}
            >
              {i18n.t('tabs.mortgageProviders', i18nOpts)}
            </NavLink>
          </NavItem>
        )}
        {isDocuSignConnected() && (
          <NavItem>
            <NavLink
              active={activeTab === Tabs.RESERVATION_DOCUMENT}
              onClick={() => setActiveTab(Tabs.RESERVATION_DOCUMENT)}
            >
              {i18n.t('tabs.reservationDocument', i18nOpts)}
            </NavLink>
          </NavItem>
        )}
        {isAdmin() && (
          <NavItem>
            <NavLink
              active={activeTab === Tabs.MODIFICATIONS}
              onClick={() => setActiveTab(Tabs.MODIFICATIONS)}
            >
              {i18n.t('tabs.modifications', i18nOpts)}
            </NavLink>
          </NavItem>
        )}
      </Nav>

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.OVERVIEW}>
          <Overview />
        </TabPane>
        <TabPane tabId={Tabs.PRODUCT_TYPES}>
          <ProductTypes />
        </TabPane>
        <TabPane tabId={Tabs.MODELS}>
          <Models />
        </TabPane>
        <TabPane tabId={Tabs.PHASES}>
          <Phases />
        </TabPane>
        <TabPane tabId={Tabs.IMAGES}>
          <Images />
        </TabPane>
        <TabPane tabId={Tabs.SALES_REPRESENTATIVES}>
          <SalesRepresentatives />
        </TabPane>
        {isPreApprovalEnabled() && (
          <TabPane tabId={Tabs.MORTGAGE_PROVIDERS}>
            <MortgageProviders />
          </TabPane>
        )}
        {isDocuSignConnected() && (
          <TabPane tabId={Tabs.RESERVATION_DOCUMENT}>
            <ReservationDocument />
          </TabPane>
        )}
        {isAdmin() && (
          <TabPane tabId={Tabs.MODIFICATIONS}>
            <Modifications instance={community} />
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

Sections.propTypes = {};

Sections.defaultProps = {};

export default connect((store) => ({
  community: store.communities.community,
}))(Sections);
