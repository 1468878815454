import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';

const i18nOpts = { scope: 'components.admin.overview.recentCustomers.listView.listItem.activeHomeInfo.index' };

const ActiveHomeInfo = ({ home }) => {
  if (!home) return null;

  let communityProjectName;
  const {
    beds, baths, sizeSqft, singleFamilyHome, multiFamilyHome
  } = home;
  if (multiFamilyHome) communityProjectName = multiFamilyHome?.project?.name;
  else communityProjectName = singleFamilyHome?.community?.name;

  return (
    <div className="d-flex gap-1 mt-1">
      {!v.isBlank(communityProjectName) && (
        <Badge color="primary" pill>
          {communityProjectName}
        </Badge>
      )}

      <Badge color="light" pill>
        {i18n.t('bed', { ...i18nOpts, beds })}
        {` / ${i18n.t('bath', { ...i18nOpts, baths })}`}
        {` / ${i18n.t('sizeSqft', { ...i18nOpts, size: sizeSqft })}`}
      </Badge>
    </div>
  );
};

ActiveHomeInfo.propTypes = {
  home: PropTypes.objectOf(PropTypes.any)
};

ActiveHomeInfo.defaultProps = {
  home: null
};

export default ActiveHomeInfo;
