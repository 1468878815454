import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { Link } from 'gatsby';
import v from 'voca';
import { Helmet } from 'react-helmet';

import styles from './List.module.scss';
import ListEmpty from '../../../common/ListEmpty';
import { getCustomers, setCustomerColumns } from '../../../../store/actions/customerActions';
import ListPagination from '../../../common/ListPagination';
import SearchBar from '../../../common/SearchBar';
import {
  isMasterAdmin, isTecSupport, isCompanyAdmin, isSalesLead
} from '../../../../utils/authUtils';
import Breadcrumbs from '../../../common/Breadcrumbs';
import { getPathname } from '../../../../utils/paramsUtils';
import QRCodeModal from '../../../global/QRCodeModal';
import ListView from './ListView';
import { CustomerColumns, getCustomerFilters } from './utils';
import useSortOptions from './ListView/hooks/useSortOptions';
import AssignedFilter from './AssignedFilter';
import useHomeRental from '../../../../hooks/useHomeRental';
import VerificationDirectLinkModal from '../../../global/VerificationDirectLinkModal';
import ColumnsPickerModal from './ColumnsPickerModal';
import EditColumnsButton from './EditColumnsButton';
import FiltersModal from './FiltersModal';
import FiltersButton from './FiltersButton';
import CustomerProgressPopover
  from '../../../global/customer/CustomerProgressPopover';
import AssignedToFilter from './AssignedToFilter';
import InterestsFilter from './InterestsFilter';
import Loading from '../../../ui/Loading';
import ExportButton from './components/ExportButton';
import ExportModal from './components/ExportModal';
import useReserveNowWorkflowSteps from '../../../../hooks/useReserveNowWorkflowSteps';

const i18nOpts = { scope: 'components.admin.customers.list.index' };

const List = ({
  customers, loading, pagination, currentCompany, sortField, sortOrder,
  columns, filters, currentUser, ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const [query, setQuery] = useState();
  const [initialColumnsSet, setInitialColumnsSet] = useState(false);
  const sort = useSortOptions();
  const { typeI18nKey } = useHomeRental();
  const reserveNowWorkflowSteps = useReserveNowWorkflowSteps();

  const showOnlyDeleted = useMemo(() => {
    const pathname = getPathname();
    return pathname.includes('/deleted');
  }, []);

  const onChangePage = (page) => {
    loadCustomers(page);
  };

  const onSearch = (event, newQuery) => {
    event.preventDefault();
    setQuery(newQuery);
  };

  const getBreadcrumbItems = () => {
    const items = [
      { url: '/admin/customers', text: i18n.t(`${typeI18nKey}.title`, i18nOpts) },
    ];
    if (showOnlyDeleted) items.push({ url: '/admin/customers/deleted', text: i18n.t(`${typeI18nKey}.deletedCustomers`, i18nOpts) });
    return items;
  };

  const loadCustomers = (page = 1) => {
    const filter = getCustomerFilters(currentCompany, showOnlyDeleted, filters);

    const variables = {
      filter,
      sort,
      page,
      companyId: currentCompany.id,
      ...(currentCompany.enableVerifiedBuyingPower ? {
        withParty: true,
        withAffordability: true,
        withPropertyInfo: columns.includes(CustomerColumns.HOME_VALUATION),
        withUser: columns.includes(CustomerColumns.PRE_APPROVAL),
        withPreApproval: columns.includes(CustomerColumns.PRE_APPROVAL),
        withActiveHome: columns.includes(CustomerColumns.STATUS),
        withActiveOffer: columns.includes(CustomerColumns.STATUS),
      } : {})
    };
    if (!v.isBlank(query)) variables.filter.query = query;
    variables.filter.reserveNowWorkflowStepTypes = reserveNowWorkflowSteps
      .map((step) => step.type.toUpperCase());

    props.getCustomers(variables)
      .finally(() => setMounted(true));
  };

  const setInitialColumns = () => {
    const { customers: initialColumns = [] } = currentUser?.columnSettings || {};
    if (initialColumns && initialColumns.length) props.setCustomerColumns(initialColumns);
    setInitialColumnsSet(true);
  };

  useEffect(() => {
    if (initialColumnsSet) loadCustomers();
  }, [query, sortField, sortOrder, columns, filters, initialColumnsSet]);

  useEffect(() => {
    setInitialColumns();
  }, []);

  if (!mounted) return <Loading loading fullScreen />;

  const isSuperAdmin = isMasterAdmin() || isTecSupport();
  const breadcrumbItems = getBreadcrumbItems();
  const exportSettings = currentCompany?.exportSettings || {};

  return (
    <div>
      <Helmet title={i18n.t(`${typeI18nKey}.title`, i18nOpts)} />

      <div className="mb-4 d-flex flex-wrap gap-2">
        <Breadcrumbs items={breadcrumbItems} />

        <AssignedFilter />

        <div className="d-flex align-items-center ml-auto flex-wrap gap-3 justify-content-end">
          <div className={styles.searchBarContainer}>
            <SearchBar
              onSubmit={onSearch}
              inputProps={{ bsSize: 'md', placeholder: i18n.t('searchBar.placeholder', i18nOpts) }}
            />
          </div>

          {(isCompanyAdmin() || isSalesLead()) && (
            <div>
              <AssignedToFilter />
            </div>
          )}

          <div><InterestsFilter /></div>

          <div><EditColumnsButton /></div>

          <div><FiltersButton /></div>

          {exportSettings.customers && (
            <div><ExportButton /></div>
          )}
        </div>
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={customers} />

          <ListView />

          <div className="d-flex justify-content-between align-items-center mt-4">
            <div>
              {(isSuperAdmin || isCompanyAdmin() || isSalesLead()) && !showOnlyDeleted && (
                <Link to="/admin/customers/deleted" className="text-decoration-underline">
                  {i18n.t(`${typeI18nKey}.deletedCustomers`, i18nOpts)}
                </Link>
              )}
            </div>
            <div>
              <ListPagination pagination={pagination} onPress={onChangePage} />
            </div>
          </div>
        </>
      )}

      <VerificationDirectLinkModal />
      <QRCodeModal />
      <ColumnsPickerModal />
      <FiltersModal />
      <CustomerProgressPopover />
      <ExportModal />
    </div>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  customers: store.customers.customers,
  loading: store.customers.getCustomers.loading,
  pagination: store.customers.pagination,
  sortField: store.customers.sortField,
  sortOrder: store.customers.sortOrder,
  columns: store.customers.columns,
  filters: store.customers.filters,
  currentUser: store.users.currentUser
}), {
  getCustomers,
  setCustomerColumns
})(List);
