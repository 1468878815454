import React from 'react';
import { DropdownItem } from 'reactstrap';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import i18n from 'i18n-js';

import BaseActionsMenu from '../../../../../global/ActionsMenu';
import { ModalNames, Statuses } from '../../../../../../constants';
import { hasCompanyDomain } from '../../../../../../utils/companyUtils';
import { isAdmin } from '../../../../../../utils/authUtils';
import ModalService from '../../../../../../services/ModalService';

const ActionsMenu = ({ community, currentCompany }) => {
  const onShareLink = () => {
    const params = {
      path: `/community/${community.hashId}`,
      label: community.name
    };
    ModalService.open(ModalNames.DIRECT_LINK_MODAL, params);
  };

  const onEdit = () => {
    navigate(`/admin/communities/form?id=${community.id}`);
  };

  if (!isAdmin()) return null;

  return (
    <div>
      <BaseActionsMenu
        item={community}
        Icon={
          <HiOutlineDotsVertical className="col-gray-600" size="22" />
        }
      >
        {community.status === Statuses.PUBLISHED && hasCompanyDomain(currentCompany) && (
          <DropdownItem onClick={onShareLink}>
            {i18n.t('buttons.shareLink')}
          </DropdownItem>
        )}
        <DropdownItem onClick={onEdit}>
          {i18n.t('buttons.edit')}
        </DropdownItem>
      </BaseActionsMenu>
    </div>
  );
};

ActionsMenu.propTypes = {};

ActionsMenu.defaultProps = {};

export default connect((store) => ({
  community: store.communities.community,
  currentCompany: store.companies.currentCompany
}))(ActionsMenu);
