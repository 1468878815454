import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './TwoColumnLayout.module.scss';

const TwoColumnLayout = ({ leftComponent, rightComponent }) => {
  useEffect(() => {
    const pageContainer = document.querySelector('.page-content');

    pageContainer.classList.add('p-0');
    return () => {
      pageContainer.classList.remove('p-0');
    };
  }, []);

  return (
    <div id="two-column-layout">
      <div id="left-container" className={classNames(styles.leftContainer, 'd-none d-md-block')}>
        {leftComponent}
      </div>
      <div id="right-container" className={classNames(styles.rightContainer, 'p-3 p-md-4')}>
        {rightComponent}
      </div>
    </div>
  );
};

TwoColumnLayout.propTypes = {
  leftComponent: PropTypes.node.isRequired,
  rightComponent: PropTypes.node.isRequired,

};

TwoColumnLayout.defaultProps = {};

export default TwoColumnLayout;
