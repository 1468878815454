import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Spinner, Table } from 'reactstrap';
import momentTz from 'moment-timezone';

import { fetchMasterAdminMetricsByBuilder } from './utils';
import ListEmpty from '../../../common/ListEmpty';
import { SortOrders } from '../../../../constants';
import setSortFilter from '../../../../utils/sortUtils';
import SortableColumnHeader from '../../../common/SortableColumnHeader';
import ListItem from './ListItem';

const i18nOpts = { scope: 'components.admin.masterAdminDashboard.visitorLeadsTable.index' };

const DEFAULT_SORT = { field: 'companyName', direction: SortOrders.ASC };

const VisitorLeadsTable = ({ customDateRange, previousCustomDateRange }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState(DEFAULT_SORT);

  const loadMetrics = () => {
    setLoading(true);

    const timezone = momentTz.tz.guess();
    const filter = {
      customDateRange,
      previousCustomDateRange,
      timezone
    };
    const variables = { filter, sort };
    fetchMasterAdminMetricsByBuilder(variables)
      .then((items) => setData(items))
      .finally(() => setLoading(false));
  };

  const onSortChange = (field) => {
    const sortFilter = setSortFilter(field, sort);
    setSort(sortFilter);
  };

  useEffect(() => {
    loadMetrics();
  }, [customDateRange, sort]);

  return (
    <div className="mb-4">
      {loading ? (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      ) : (
        <>
          <ListEmpty loading={loading} items={data} />

          {data.length > 0 && (
            <Table responsive bordered className="mt-2">
              <thead>
                <tr className="text-center">
                  <SortableColumnHeader
                    field="companyName"
                    label={i18n.t('company', i18nOpts)}
                    onPress={() => onSortChange('companyName')}
                    sort={sort}
                    extraClass="text-left"
                  />
                  <SortableColumnHeader
                    field="totalVisitors"
                    label={i18n.t('totalVisitors', i18nOpts)}
                    onPress={() => onSortChange('totalVisitors')}
                    sort={sort}
                  />
                  <SortableColumnHeader
                    field="totalReturningVisitors"
                    label={i18n.t('returningVisitors', i18nOpts)}
                    onPress={() => onSortChange('totalReturningVisitors')}
                    sort={sort}
                  />
                  <SortableColumnHeader
                    field="newLeads"
                    label={i18n.t('newLeads', i18nOpts)}
                    onPress={() => onSortChange('newLeads')}
                    sort={sort}
                  />
                  <SortableColumnHeader
                    field="reservationStarted"
                    label={i18n.t('reserveStarts', i18nOpts)}
                    onPress={() => onSortChange('reservationStarted')}
                    sort={sort}
                  />
                  <SortableColumnHeader
                    field="reservationCompleted"
                    label={i18n.t('reserveCompletes', i18nOpts)}
                    onPress={() => onSortChange('reservationCompleted')}
                    sort={sort}
                  />
                  <SortableColumnHeader
                    field="verificationStarted"
                    label={i18n.t('verifiedStarts', i18nOpts)}
                    onPress={() => onSortChange('verificationStarted')}
                    sort={sort}
                  />
                  <SortableColumnHeader
                    field="verificationCompleted"
                    label={i18n.t('verifiedCompletes', i18nOpts)}
                    onPress={() => onSortChange('verificationCompleted')}
                    sort={sort}
                  />
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <ListItem key={`data-item-${item.company?.id}`} item={item} />
                ))}
              </tbody>
            </Table>
          )}
        </>
      )}
    </div>
  );
};

VisitorLeadsTable.propTypes = {
  customDateRange: PropTypes.objectOf(PropTypes.any).isRequired,
  previousCustomDateRange: PropTypes.objectOf(PropTypes.any).isRequired
};

VisitorLeadsTable.defaultProps = {};

export default VisitorLeadsTable;
