import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Spinner } from 'reactstrap';
import i18n from 'i18n-js';

import './styles.scss';
import ListEmpty from '../../../common/ListEmpty';
import { fetchDealsAsync } from './utils';
import ListPagination from '../../../common/ListPagination';
import ListView from './ListView';

const i18nOpts = { scope: 'components.admin.overview.recentDeals.index' };

const RecentDeals = ({ currentCompany }) => {
  const [loading, setLoading] = useState(false);
  const [deals, setDeals] = useState([]);
  const [pagination, setPagination] = useState(null);

  const loadDeals = (page = 1) => {
    setLoading(true);

    const variables = {
      filter: { companyId: currentCompany.id },
      companyId: currentCompany.id,
      page,
      pageSize: 3
    };

    fetchDealsAsync(variables)
      .then(({ items, pagination: currentPagination }) => {
        setDeals(items);
        setPagination(currentPagination);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const onChangePage = (page) => {
    loadDeals(page);
  };

  useEffect(() => {
    loadDeals();
  }, []);

  return (
    <Card body id="dashboard-recent-deals-card" className="h-100">
      <h4>{i18n.t('title', i18nOpts)}</h4>

      {loading ? (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      ) : (
        <div>
          <ListEmpty loading={loading} items={deals} />

          <ListView deals={deals} />

          <div className="text-right">
            <ListPagination pagination={pagination} onPress={onChangePage} />
          </div>
        </div>
      )}
    </Card>
  );
};

RecentDeals.propTypes = {};

RecentDeals.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {})(RecentDeals);
