import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import momentTz from 'moment-timezone';
import { navigate } from 'gatsby';

import styles from './Overview.module.scss';
import TrafficByDevice from './TrafficByDevice';
import LeadLocation from './LeadLocation';
import {
  dateRanges,
  getLastSevenDaysRange,
  setDaysRangeInput
} from '../../../utils/dateUtils';
import DateFilter from '../../common/DateFilter';
import DashboardMetrics from './DashboardMetrics';
import VisitorTraffic from './VisitorTraffic';
import LeadGeneration from './LeadGeneration';
import RecentCustomers from './RecentCustomers';
import TrafficByCommunityProject from './TrafficByCommunityProject';
import RecentDeals from './RecentDeals';
import useIsReserveNowEnabled from '../../../hooks/useIsReserveNowEnabled';
import TrafficByModelBuildingModel from './TrafficByModelBuildingModel';
import DateRangeNavigator from '../../common/DateRangeNavigator';

const i18nOpts = { scope: 'components.admin.overview.index' };

const Overview = ({ currentCompany }) => {
  const [filter, setFilter] = useState(null);
  const [selectedRange, setSelectedRange] = useState('Last 7 Days');
  const [dateRange, setDateRange] = useState(getLastSevenDaysRange());
  const [customDateRange, setCustomDateRange] = useState(
    () => setDaysRangeInput(getLastSevenDaysRange())
  );
  const [previousCustomDateRange, setPreviousCustomDateRange] = useState(() => {
    const [startDate] = getLastSevenDaysRange();

    const previousStartDate = new Date(startDate);
    const previousEndDate = new Date(startDate);
    previousStartDate.setDate(startDate.getDate() - 7);
    previousEndDate.setDate(startDate.getDate() - 1);

    return setDaysRangeInput([previousStartDate, previousEndDate]);
  });

  const isReserveNowEnabled = useIsReserveNowEnabled();

  const updatePreviousCustomRange = (rangeKey, currentRange) => {
    const rangeConfig = dateRanges[rangeKey];

    let newRange = { startDate: null, endDate: null };
    if (rangeConfig) {
      newRange = setDaysRangeInput(rangeConfig.navigate(currentRange, 'prev'));
    }

    setPreviousCustomDateRange(newRange);
  };

  const updateDateRange = (rangeKey, newRange) => {
    updatePreviousCustomRange(rangeKey, newRange);

    setDateRange(newRange);
    setCustomDateRange(setDaysRangeInput(newRange));
  };

  const onDatePicked = (event, picker) => {
    const rangeKey = picker.chosenLabel;
    const newRange = [
      picker.startDate.startOf('day').toDate(),
      picker.endDate.endOf('day').toDate()
    ];

    setSelectedRange(rangeKey);
    updateDateRange(rangeKey, newRange);
  };

  const buildFilters = () => {
    const timezone = momentTz.tz.guess();

    const filters = {
      companyId: currentCompany.id,
      customDateRange,
      timezone
    };
    setFilter(filters);
  };

  const getItemFromChart = (element, array) => {
    if (!element.length) return null;
    const { index: i } = element[0];
    return array[i];
  };

  const onCommunityOrProjectPress = (element, array) => {
    const communityOrProject = getItemFromChart(element, array);
    if (communityOrProject?.type === 'community') {
      navigate(`/admin/communities/${communityOrProject.id}?from=dashboard`);
    } else if (communityOrProject?.type === 'project') {
      navigate(`/admin/projects/${communityOrProject.id}?from=dashboard`);
    }
  };

  const handleNavigate = (newRange) => {
    updateDateRange(selectedRange, newRange);
  };

  useEffect(() => {
    buildFilters();
  }, [customDateRange]);

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="d-flex align-items-center justify-content-between flex-wrap gap-3 mb-4">
        <div className="flex-grow-1">
          <h2 className="mb-0">{ i18n.t('title', i18nOpts) }</h2>
        </div>

        <DateFilter
          dateRange={dateRange}
          onDatePicked={onDatePicked}
          containerClassName="justify-content-end"
        />

        <DateRangeNavigator
          dateRange={dateRange}
          selectedRange={selectedRange}
          onNavigate={handleNavigate}
        />
      </div>

      <DashboardMetrics
        customDateRange={customDateRange}
        previousCustomDateRange={previousCustomDateRange}
      />

      <Row className="align-items-stretch">
        <Col md="8">
          <Row className="h-100">
            <Col xs="12" className={styles.col}>
              <VisitorTraffic filter={filter} />
            </Col>
            <Col xs="12" className={styles.col}>
              <LeadGeneration filter={filter} />
            </Col>
          </Row>
        </Col>
        <Col md="4" className={styles.col}>
          <RecentCustomers />
        </Col>
      </Row>

      <Row className="align-items-stretch">
        <Col md="6" className={styles.col}>
          <TrafficByCommunityProject filter={filter} onCommunityPress={onCommunityOrProjectPress} />
        </Col>
        <Col md="6" className={styles.col}>
          <TrafficByModelBuildingModel filter={filter} onModelPress={() => {}} />
        </Col>
      </Row>

      {isReserveNowEnabled && (
        <Row>
          <Col className={styles.col}>
            <RecentDeals />
          </Col>
        </Row>
      )}

      <Row className="align-items-stretch">
        <Col md="6" className={styles.col}>
          <LeadLocation filter={filter} />
        </Col>
        <Col md="6" className={styles.col}>
          <TrafficByDevice filter={filter} />
        </Col>
      </Row>
    </div>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {})(Overview);
