export const Tabs = Object.freeze({
  OVERVIEW: 'overview',
  PRODUCT_TYPES: 'productTypes',
  MODELS: 'models',
  PHASES: 'phases',
  IMAGES: 'images',
  SALES_REPRESENTATIVES: 'salesRepresentatives',
  MORTGAGE_PROVIDERS: 'mortgageProviders',
  RESERVATION_DOCUMENT: 'reservationDocument',
  MODIFICATIONS: 'modifications'
});
