import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import classnames from 'classnames';
import moment from 'moment';
import { Table } from 'reactstrap';

import { isRecently } from '../../../../utils/dateUtils';
import { CompaniesSortFields, DateFormats } from '../../../../constants';
import ActionsMenu from '../../../global/ActionsMenu';
import { isMasterAdmin } from '../../../../utils/authUtils';
import { formatCurrency } from '../../../../utils/currencyUtils';
import SortableColumnHeader from '../../../common/SortableColumnHeader';

const i18nOpts = { scope: 'components.admin.companies.table.index' };

const CompaniesTable = ({
  companies, isArchived, onDelete, onEdit, deleteMessage, deleteText, sort, onSortChange
}) => {
  const isMasterAdminRole = isMasterAdmin();

  return (
    <div className="table-responsive mb-4">
      <Table bordered>
        <thead>
          <tr>
            <SortableColumnHeader
              field={CompaniesSortFields.NAME}
              label={i18n.t('name', i18nOpts)}
              onPress={() => onSortChange(CompaniesSortFields.NAME)}
              sort={sort}
            />
            {isMasterAdminRole && (
              <>
                <SortableColumnHeader
                  field={CompaniesSortFields.MONTHLY_AMOUNT}
                  label={i18n.t('monthlyAmount', i18nOpts)}
                  onPress={() => onSortChange(CompaniesSortFields.MONTHLY_AMOUNT)}
                  sort={sort}
                />
                <SortableColumnHeader
                  field={CompaniesSortFields.RENEWAL_DATE}
                  label={i18n.t('renewalDate', i18nOpts)}
                  onPress={() => onSortChange(CompaniesSortFields.RENEWAL_DATE)}
                  sort={sort}
                />
              </>
            )}
            <SortableColumnHeader
              field={CompaniesSortFields.CREATED_AT}
              label={i18n.t('createdAt', i18nOpts)}
              onPress={() => onSortChange(CompaniesSortFields.CREATED_AT)}
              sort={sort}
            />
            {isArchived && (
              <SortableColumnHeader
                field={CompaniesSortFields.ARCHIVED_AT}
                label={i18n.t('archivedAt', i18nOpts)}
                onPress={() => onSortChange(CompaniesSortFields.ARCHIVED_AT)}
                sort={sort}
              />
            )}
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {
            companies.map((company) => (
              <tr key={`company-${company.id}`}>
                <td>{company.name}</td>
                {isMasterAdminRole && (
                  <>
                    <td>{formatCurrency(company.contract?.monthlyAmount)}</td>
                    <td>
                      {company.contract?.renewalDate && (
                        moment(company.contract?.renewalDate).format(DateFormats.MMM_DD_YYYY)
                      )}
                    </td>
                  </>
                )}
                <td>
                  <span className={classnames({ 'text-success': isRecently(company.createdAt) })}>
                    {moment(company.createdAt).format(DateFormats.MMM_DD_YYYY)}
                  </span>
                </td>
                {isArchived && (
                  <td>
                    <span className={classnames({ 'text-success': isRecently(company.archivedAt) })}>
                      {moment(company.archivedAt).format(DateFormats.MMM_DD_YYYY)}
                    </span>
                  </td>
                )}
                <td className="text-right">
                  <ActionsMenu
                    item={company}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    deleteText={deleteText}
                    deleteMessage={deleteMessage}
                  />
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  );
};

CompaniesTable.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.any).isRequired,
  isArchived: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  deleteMessage: PropTypes.string,
  deleteText: PropTypes.string,
  sort: PropTypes.objectOf(PropTypes.any),
  onSortChange: PropTypes.func
};

CompaniesTable.defaultProps = {
  isArchived: false,
  onEdit: null,
  deleteMessage: '',
  deleteText: '',
  sort: {},
  onSortChange: () => {}
};

export default CompaniesTable;
