import React, { useEffect, useState } from 'react';
import { Card, Spinner } from 'reactstrap';
import i18n from 'i18n-js';
import { Doughnut } from 'react-chartjs-2';
import { connect } from 'react-redux';

import api from '../../../../api';
import { getTrafficByDeviceQuery } from '../../../../graphql';
import { addSampleVisitors } from '../../../../utils/sampleDataUtils';
import { formatRange } from '../../../../utils/dateUtils';
import ActionsMenu from '../components/ActionsMenu';
import { exportToCSV } from '../utils';

const i18nOpts = { scope: 'components.admin.overview.trafficByDevice.index' };

const DEVICE_TYPES = Object.freeze({
  mobile: i18n.t('mobile', i18nOpts),
  desktop: i18n.t('desktop', i18nOpts),
  tablet: i18n.t('tablet', i18nOpts)
});

function getChartData(deviceTypes) {
  return {
    labels: deviceTypes.map((c) => DEVICE_TYPES[c.name].toUpperCase()),
    datasets: [
      {
        backgroundColor: ['#4D4D4D', '#00AF8C', '#BAC638'],
        data: deviceTypes.map((c) => c.visitorsCount)
      }
    ]
  };
}

const legend = {
  align: 'center',
  labels: {
    font: {
      color: '#000000',
      weight: '800',
      size: 10
    },
    boxWidth: 10
  }
};

const options = {
  plugins: {
    legend
  },
  responsive: true,
  maintainAspectRatio: false
};

function fetchDeviceTypesAsync(filter) {
  const variables = { reportFilter: filter };
  return api.graphql(getTrafficByDeviceQuery, variables)
    .then(({ data: { results } }) => Promise.resolve(results));
}

function generateSampleDataAsync() {
  const items = Object.keys(DEVICE_TYPES).map((i) => ({ name: i }));
  const deviceTypes = addSampleVisitors(items);
  return Promise.resolve(deviceTypes);
}

function fetchTrafficDataAsync(company, filter) {
  return company.enableSampleData ? generateSampleDataAsync()
    : fetchDeviceTypesAsync(filter);
}

const TrafficByDevice = ({ currentCompany, filter }) => {
  const [loading, setLoading] = useState(false);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [rangeDateLabel, setRangeDateLabel] = useState('');

  const onExport = () => {
    const headers = [i18n.t('deviceType', i18nOpts), i18n.t('visitors', i18nOpts)];
    const rows = deviceTypes.map((device) => [
      DEVICE_TYPES[device.name],
      device.visitorsCount
    ]);
    exportToCSV(headers, rows, `Our Visitors ${rangeDateLabel}.csv`);
  };

  useEffect(() => {
    if (!filter) return;

    setLoading(true);
    setRangeDateLabel(formatRange(filter?.customDateRange));
    fetchTrafficDataAsync(currentCompany, filter)
      .then((items) => setDeviceTypes(items))
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [filter]);

  const data = getChartData(deviceTypes);

  return (
    <Card body className="h-100">
      <div className="d-flex align-items-center">
        <h4>{i18n.t('title', i18nOpts)}</h4>
        <div className="ml-auto">
          <ActionsMenu onExport={onExport} />
        </div>
      </div>

      <div className="text-uppercase mb-3 d-flex justify-content-between align-items-center">
        <small className="align-self-start">{i18n.t('description', i18nOpts)}</small>
        <small className="align-self-end">{rangeDateLabel}</small>
      </div>

      {loading ? (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      ) : (
        <div style={{ height: '275px' }}>
          <Doughnut data={data} options={options} />
        </div>
      )}
    </Card>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  currentCommunity: store.overview.community,
  currentModel: store.overview.model
}), {})(TrafficByDevice);
