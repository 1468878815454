import React from 'react';
import PropTypes from 'prop-types';

const Beds = ({ unit }) => {
  const { buildingModel } = unit;

  if (!buildingModel) return null;

  return (
    <div>
      {buildingModel.bedrooms}
    </div>
  );
};

Beds.propTypes = {
  unit: PropTypes.objectOf(PropTypes.any).isRequired
};

Beds.defaultProps = {};

export default Beds;
