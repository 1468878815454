import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const ExpandableSection = ({ title, containerId, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div id={containerId} className={classNames('expandable-container', { opened: isOpen })}>
      <hr />
      <div role="button" aria-hidden onClick={onToggle} className="d-flex align-items-center">
        <div className="flex-grow-1">
          <span className="font-14 font-weight-500">{title}</span>
        </div>
        <div className="expand-icon">
          {isOpen ? (
            <FiMinusCircle size="1.5rem" />
          ) : (
            <FiPlusCircle size="1.5rem" />
          )}
        </div>
      </div>

      <Collapse isOpen={isOpen}>
        <div className="pt-3">
          {children}
        </div>
      </Collapse>
    </div>
  );
};

ExpandableSection.propTypes = {
  title: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired
};

ExpandableSection.defaultProps = {};

export default ExpandableSection;
