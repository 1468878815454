import i18n from 'i18n-js';

import { Tabs } from '../../utils';
import { isMasterAdmin } from '../../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.contract.index' };

export function getSettings() {
  if (!isMasterAdmin()) return [];

  return [
    {
      id: 'contractStartDate',
      label: i18n.t('startDate', i18nOpts),
      containerId: 'contractStartDateContainer',
      tabId: Tabs.CONTRACT
    },
    {
      id: 'contractRenewalDate',
      label: i18n.t('renewalDate', i18nOpts),
      containerId: 'contractRenewalDateContainer',
      tabId: Tabs.CONTRACT
    },
    {
      id: 'contractMonthlyAmount',
      label: i18n.t('monthlyAmount', i18nOpts),
      containerId: 'contractMonthlyAmountContainer',
      tabId: Tabs.CONTRACT
    },
    {
      id: 'contractType',
      label: i18n.t('type', i18nOpts),
      containerId: 'contractTypeContainer',
      tabId: Tabs.CONTRACT
    },
    {
      id: 'contractStage',
      label: i18n.t('stage', i18nOpts),
      containerId: 'contractStageContainer',
      tabId: Tabs.CONTRACT
    }
  ];
}
