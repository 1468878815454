import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import i18n from 'i18n-js';

import ListItem from './ListItem';

const i18nOpts = { scope: 'components.admin.overview.recentDeals.listView.index' };

const ListView = ({ deals }) => {
  if (!deals || deals.length === 0) return null;

  return (
    <Table responsive bordered style={{ height: '1px' }}>
      <thead>
        <tr>
          <th>{i18n.t('customer', i18nOpts)}</th>
          <th>{i18n.t('dealState', i18nOpts)}</th>
          <th>{i18n.t('dealPrice', i18nOpts)}</th>
          <th>{i18n.t('modelUnit', i18nOpts)}</th>
          <th>{i18n.t('communityProject', i18nOpts)}</th>
          <th>{i18n.t('lastActive', i18nOpts)}</th>
        </tr>
      </thead>
      <tbody>
        {
          deals.map((deal) => (
            <ListItem key={`deal-${deal.contactId}`} deal={deal} />
          ))
        }
      </tbody>
    </Table>
  );
};

ListView.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
};

ListView.defaultProps = {
  deals: []
};

export default ListView;
