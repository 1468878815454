import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import ExpandableSection from '../../../components/ExpandableSection';
import { CKEditor } from '../../../../../../ui';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.communications.emailMessagesSection.index' };

const EmailMessageItem = ({ name, value, onTextChange }) => (
  <ExpandableSection title={i18n.t(`sections.${name}`, i18nOpts)} containerId={`${name}EmailMessageContainer`}>
    <Row>
      <Col md={6}>
        <CKEditor name={name} data={value} onTextChange={onTextChange} />
      </Col>
    </Row>
  </ExpandableSection>
);

const EmailMessagesSection = ({ form, onTextChange }) => {
  const emailMessages = form.emailMessages || {};

  const onChangeEmailMessage = (event) => {
    const { name, value } = event.target;

    const newEmailMessages = { ...emailMessages, [name]: value };
    onTextChange({ target: { name: 'emailMessages', value: newEmailMessages } });
  };

  return (
    <>
      <h4 className="font-weight-500" id="emailMessagesSectionContainer">{i18n.t('title', i18nOpts)}</h4>
      <EmailMessageItem
        name="savedHomeReservationReminderQP"
        value={emailMessages.savedHomeReservationReminderQP}
        onTextChange={onChangeEmailMessage}
      />
      <EmailMessageItem
        name="savedHomeReservationReminderUnit"
        value={emailMessages.savedHomeReservationReminderUnit}
        onTextChange={onChangeEmailMessage}
      />
    </>
  );
};

EmailMessagesSection.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onTextChange: PropTypes.func
};

EmailMessagesSection.defaultProps = {
  onTextChange: () => {
  }
};

export default EmailMessagesSection;
