import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import momentTz from 'moment-timezone';

import styles from './Overview.module.scss';
import { getLastSevenDaysRange, setDaysRangeInput } from '../../../../../../utils/dateUtils';
import VisitorTraffic from '../../../../Overview/VisitorTraffic';
import LeadGeneration from '../../../../Overview/LeadGeneration';
import TrafficByModelBuildingModel from '../../../../Overview/TrafficByModelBuildingModel';
import MostPopularSelections from '../../../../Overview/MostPopularSelections';
import LeadLocation from '../../../../Overview/LeadLocation';
import DateFilter from '../../../../../common/DateFilter';

const Overview = ({ currentCompany, project }) => {
  const [filter, setFilter] = useState(null);
  const [dateRange, setDateRange] = useState(getLastSevenDaysRange());
  const [customDateRange, setCustomDateRange] = useState(
    () => setDaysRangeInput(getLastSevenDaysRange())
  );

  const updateDateRange = (rangeKey, newRange) => {
    setDateRange(newRange);
    setCustomDateRange(setDaysRangeInput(newRange));
  };

  const onDatePicked = (event, picker) => {
    const rangeKey = picker.chosenLabel;
    const newRange = [
      picker.startDate.startOf('day').toDate(),
      picker.endDate.endOf('day').toDate()
    ];

    updateDateRange(rangeKey, newRange);
  };

  const buildFilters = () => {
    const timezone = momentTz.tz.guess();

    const filters = {
      companyId: currentCompany.id,
      customDateRange,
      projectId: project.id,
      timezone
    };
    setFilter(filters);
  };

  useEffect(() => {
    buildFilters();
  }, [customDateRange]);

  return (
    <div>
      <div className="mb-4">
        <DateFilter
          dateRange={dateRange}
          onDatePicked={onDatePicked}
          containerClassName="justify-content-end"
        />
      </div>

      <Row className="align-items-stretch">
        <Col lg="6" className={styles.col}>
          <VisitorTraffic filter={filter} />
        </Col>
        <Col lg="6" className={styles.col}>
          <LeadGeneration filter={filter} />
        </Col>
      </Row>

      <Row className="align-items-stretch">
        <Col lg="6" className={styles.col}>
          <TrafficByModelBuildingModel filter={filter} onModelPress={() => {}} />
        </Col>
        <Col lg="6" className={styles.col}>
          <MostPopularSelections filter={filter} />
        </Col>
      </Row>

      <Row className="align-items-stretch">
        <Col lg="6" className={styles.col}>
          <LeadLocation filter={filter} />
        </Col>
      </Row>
    </div>
  );
};

Overview.propTypes = {};

Overview.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  project: store.projects.project
}))(Overview);
