import React from 'react';
import { connect } from 'react-redux';
import Switch from 'rc-switch';
import i18n from 'i18n-js';
import v from 'voca';

import { getCreatorDisplayName, getLastModifierDisplayName } from '../../../../../../utils/auditUtils';
import LotMapSettings from './LotMapSettings';
import TruncateDescription from '../../../../../common/TruncateDescription';
import { Statuses } from '../../../../../../constants';
import { setCommunityAttributes } from '../../../../../../store/actions/communityActions';
import { isAdmin } from '../../../../../../utils/authUtils';
import { updateCommunityStatusAsync } from './utils';
import { confirmAlert } from '../../../../../global/confirmAlert';

const i18nOpts = { scope: 'components.admin.communities.show.summary.details.index' };

const Details = ({ community, ...props }) => {
  if (!community) return null;

  const updateCommunityStatus = (isPublished) => {
    const input = { id: community.id, status: isPublished ? Statuses.PUBLISHED : Statuses.DRAFT };

    updateCommunityStatusAsync(input)
      .then((item) => {
        props.setCommunityAttributes(community.id, { ...item });
      })
      .catch(() => {});
  };

  const onTogglePublished = (checked) => {
    const message = i18n.t(`messages.${checked ? 'publish' : 'unpublish'}`, { itemName: community.name });
    const options = {
      title: null,
      message: `<div class="font-18">${message}</div>`
    };
    confirmAlert(() => updateCommunityStatus(checked), options);
  };

  const creatorName = getCreatorDisplayName(community);
  const lastModifierName = getLastModifierDisplayName(community);

  return (
    <div className="d-flex flex-column gap-3">
      <div>
        <h2 className="mb-0">{community.name}</h2>
      </div>

      <div className="d-flex align-items-center">
        <div><strong>{i18n.t('statuses.published')}</strong></div>
        <div className="ml-auto">
          <Switch checked={community.status === Statuses.PUBLISHED} onChange={onTogglePublished} />
        </div>
      </div>

      {!v.isBlank(community.imageUrl) && (
        <img src={community.imageUrl} alt={community.name} className="w-100" />
      )}

      {!v.isBlank(community.hashId) && (
        <div>
          <div className="label">{i18n.t('communityId', i18nOpts)}</div>
          <div><strong>{community.hashId}</strong></div>
        </div>
      )}

      {community.city && (
        <>
          <div>
            <div className="label">{i18n.t('city', i18nOpts)}</div>
            <div><strong>{community.city.name}</strong></div>
          </div>

          {!v.isBlank(community.city.state) && (
            <div>
              <div className="label">{i18n.t('province', i18nOpts)}</div>
              <div><strong>{community.city.state}</strong></div>
            </div>
          )}
        </>
      )}

      {!v.isBlank(community.subRegion) && (
        <div>
          <div className="label">{i18n.t('subRegion', i18nOpts)}</div>
          <div><strong>{community.subRegion}</strong></div>
        </div>
      )}

      {!v.isBlank(community.address) && (
        <div>
          <div className="label">{i18n.t('address', i18nOpts)}</div>
          <div><strong>{community.address}</strong></div>
        </div>
      )}

      {!v.isBlank(community.description) && (
        <div>
          <div className="label">{i18n.t('description', i18nOpts)}</div>
          <TruncateDescription description={community.description} />
        </div>
      )}

      {!v.isBlank(community.websiteUrl) && (
        <div>
          <div className="label">{i18n.t('websiteUrl', i18nOpts)}</div>
          <div><strong>{community.websiteUrl}</strong></div>
        </div>
      )}

      <LotMapSettings community={community} />

      {!v.isBlank(community.salesContactName) && (
        <div>
          <div className="label">{i18n.t('salesContactName', i18nOpts)}</div>
          <div className="white-space-pre-line"><strong>{community.salesContactName}</strong></div>
        </div>
      )}

      {!v.isBlank(community.salesAddress) && (
        <div>
          <div className="label">{i18n.t('salesAddress', i18nOpts)}</div>
          <div className="white-space-pre-line"><strong>{community.salesAddress}</strong></div>
        </div>
      )}

      {!v.isBlank(community.externalId) && (
        <div>
          <div className="label">{i18n.t('externalId', i18nOpts)}</div>
          <div><strong>{community.externalId}</strong></div>
        </div>
      )}

      {isAdmin() && (
        <>
          {!v.isBlank(creatorName) && (
            <div>
              <div className="label">{i18n.t('createdBy', i18nOpts)}</div>
              <div><strong>{creatorName}</strong></div>
            </div>
          )}

          {!v.isBlank(lastModifierName) && (
            <div>
              <div className="label">{i18n.t('lastModifiedBy', i18nOpts)}</div>
              <div><strong>{lastModifierName}</strong></div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

Details.propTypes = {};

Details.defaultProps = {};

export default connect((store) => ({
  community: store.communities.community
}), {
  setCommunityAttributes
})(Details);
