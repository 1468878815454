import React from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';

import ListHeader from './ListHeader';
import ListItem from './ListItem';

const ListView = ({ units }) => {
  if (!units.length) return null;

  return (
    <Table responsive bordered id="units-table">
      <thead>
        <ListHeader />
      </thead>
      <tbody>
        {
          units.map((unit) => (
            <ListItem key={`unit-${unit.id}`} unit={unit} />
          ))
        }
      </tbody>
    </Table>
  );
};

ListView.propTypes = {
  units: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
};

ListView.defaultProps = {
  units: []
};

export default ListView;
