import api from '../../../../api';
import { getCompanyDashboardMetricsQuery } from '../../../../graphql';

export const Metrics = Object.freeze({
  VISITORS: 'VISITORS',
  RETURNING_VISITORS: 'RETURNING_VISITORS',
  NEW_LEADS: 'NEW_LEADS',
  RESERVATIONS: 'RESERVATIONS',
  VERIFICATIONS: 'VERIFICATIONS'
});

export function fetchDashboardMetrics(variables = {}) {
  return api.graphql(getCompanyDashboardMetricsQuery, variables)
    .then(({ data: { result } }) => result);
}
