import React from 'react';
import PropTypes from 'prop-types';

import { UnitColumns } from '../../../../../../../../constants';
import SuiteNumber from './SuiteNumber';
import Floor from './Floor';
import Beds from './Beds';
import Baths from './Baths';
import Project from './Project';
import BuildingModel from './BuildingModel';
import Size from './Size';
import Price from './Price';
import Availability from './Availability';

const CustomColumnValue = ({ column, unit }) => {
  switch (column) {
    case UnitColumns.PROJECT:
      return <Project unit={unit} />;

    case UnitColumns.BUILDING_MODEL:
      return <BuildingModel unit={unit} />;

    case UnitColumns.SUITE_NUMBER:
      return <SuiteNumber unit={unit} />;

    case UnitColumns.FLOOR:
      return <Floor unit={unit} />;

    case UnitColumns.BEDROOMS:
      return <Beds unit={unit} />;

    case UnitColumns.BATHROOMS:
      return <Baths unit={unit} />;

    case UnitColumns.SIZE:
      return <Size unit={unit} />;

    case UnitColumns.PRICE:
      return <Price unit={unit} />;

    case UnitColumns.AVAILABILITY:
      return <Availability unit={unit} />;

    default:
      return null;
  }
};

CustomColumnValue.propTypes = {
  column: PropTypes.oneOf(Object.values(UnitColumns)).isRequired,
  unit: PropTypes.objectOf(PropTypes.any).isRequired
};

CustomColumnValue.defaultProps = {};

export default CustomColumnValue;
