import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import api from '../../../../../../api';
import ListItem from './ListItem';
import AddDropdown from './AddDropdown';
import { listProjectPackagesQuery } from '../../../../../../graphql';
import { reloadProjectPackages } from '../../../../../../store/actions/projectPackageActions';
import { isAdmin } from '../../../../../../utils/authUtils';

function fetchProjectPackagesAsync(project) {
  const variables = { filter: { projectId: project.id } };

  return api.graphql(listProjectPackagesQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

function fetchPackagesAsync(companyId) {
  const variables = { filter: { companyId, isMultifamily: true } };

  return api.graphql(`
    query ListPackages($filter: PackageFilterInput) {
      items: listPackages(filter: $filter) {
        id
        name
      }
    }
  `, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

const Packages = ({
  project, reload, currentCompany, ...props
}) => {
  const [projectPackages, setProjectPackages] = useState([]);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    loadProjectPackages();
    loadPackages();
  }, []);

  useEffect(() => {
    if (!reload) return;

    loadProjectPackages();
  }, [reload]);

  const loadProjectPackages = () => {
    fetchProjectPackagesAsync(project)
      .then((items) => {
        setProjectPackages(items);
        props.reloadProjectPackages(false);
      })
      .catch(() => {});
  };

  const loadPackages = () => {
    fetchPackagesAsync(currentCompany.id)
      .then((items) => setPackages(items))
      .catch(() => {});
  };

  const loadAvailablePackages = () => packages.filter(
    (p) => !projectPackages.some((pp) => pp.package.id === p.id)
  );

  const availablePackages = loadAvailablePackages();

  if (!project) return null;

  return (
    <div>
      {isAdmin() && (
        <div className="d-flex justify-content-end mb-3">
          <AddDropdown availablePackages={availablePackages} project={project} />
        </div>
      )}

      {
        projectPackages.map((pp) => (
          <ListItem projectPackage={pp} key={`project-package-${pp.id}`} />
        ))
      }
    </div>
  );
};

export default connect((store) => ({
  project: store.projects.project,
  reload: store.projectPackages.reload,
  currentCompany: store.companies.currentCompany
}), {
  reloadProjectPackages
})(Packages);
