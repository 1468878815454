import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ImagesManager from '../../../../../global/ImagesManager';
import {
  deleteCommunityImageAsync,
  fetchCommunityImagesAsync,
  saveCommunityImageAsync,
  sortCommunityImagesAsync
} from './utils';

const Images = ({ community }) => {
  const [communityImages, setCommunityImages] = useState([]);

  useEffect(() => {
    loadCommunityImages();
  }, []);

  const onDrop = (images) => {
    const promises = images.map((file) => uploadImage(file));
    return Promise.all(promises)
      .finally(() => loadCommunityImages());
  };

  const uploadImage = (image) => {
    const input = {
      communityId: community.id
    };

    const variables = { input, image };
    return saveCommunityImageAsync(variables);
  };

  const loadCommunityImages = () => {
    const variables = { filter: { communityId: community.id } };
    fetchCommunityImagesAsync(variables)
      .then((items) => setCommunityImages(items))
      .catch(() => setCommunityImages([]));
  };

  const onSetCommunityImages = (images) => {
    setCommunityImages(images);
  };

  const onSortCommunityImages = () => {
    const ids = communityImages.map((ci) => ci.id);
    const variables = { communityId: community.id, ids };

    sortCommunityImagesAsync(variables);
  };

  const onDeleteCommunityImage = (communityImage) => {
    deleteCommunityImageAsync(communityImage.id)
      .then(() => {
        const filteredImages = communityImages.filter((ci) => ci.id !== communityImage.id);
        setCommunityImages(filteredImages);
      })
      .catch(() => {});
  };

  return (
    <ImagesManager
      images={communityImages}
      onSetImages={onSetCommunityImages}
      onSortImages={onSortCommunityImages}
      onDeleteImage={onDeleteCommunityImage}
      onDrop={onDrop}
    />
  );
};

export default connect((store) => ({
  community: store.communities.community,
}), {
})(Images);
