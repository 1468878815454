import { paginationFragment } from './fragments/pagination';

const fragment = `
  contactId
  contact {
    id
    name
    email
    coBuyersCount(companyId: $companyId)
    user {
      preApproval {
        id
        verificationMethod
        documentUrl
      }
    }
    companyContact(companyId: $companyId) {
      affordability {
        maxHomeAffordability
        assessedAt
        monthlyMortgagePayment
        maxRentingAmount
        annualIncome
      }
    }
    party(companyId: $companyId) {
      affordability {
        maxHomeAffordability
        assessedAt
        monthlyMortgagePayment
        maxRentingAmount
        annualIncome
      } 
    }
  }
  home {
    id
    beds
    baths
    sizeSqft
    homeUrl
    totalOffers @include(if: $withTotalOffers)
    offer {
      id
      offerPrice
      isUsingRealtor
      realtorName
      status
      statusChangeDates
      signStatus
      signStatusChangeDates
      createdAt
    }
    deposit {
      id
      status
      statusChangeDates
    }
    singleFamilyHome {
      model {
        id
        name
        defaultImageUrl
        productType {
          id
          name
        }
      }
      community {
        id
        name
        city {
          id
          name
        }
      }
      isLot
      lot
      isQuickPossession
      quickPossession {
        id
        address
      }
      elevation {
        id
        imageUrl
      }
    }
    multiFamilyHome {
      project {
        id
        name
        address
        city {
          id
          name
        }
      }
      unit {
        id
        name
        address
        defaultImageUrl
        buildingModel {
          id
          name
        }
      }
    }
  }
  customerInvitation {
    id
    home {
      __typename
      ... on QuickPossession {
        id
        hashId
        address
        imageUrl
        community {
          id
          name
        }
        model {
          id
          name
        }
      }
      ... on Unit {
        id
        hashId
        name
        address
        defaultImageUrl
        buildingModel {
          id
          name
          project {
            id
            name
            address
          }
        }
      }
    }
  }
  isReservationComplete @include(if: $withIsReservationComplete)
  createdAt
`;

export const listDealsQuery = `
  query ListDeals(
    $filter: DealFilterInput!
    $companyId: Int!
    $withTotalOffers: Boolean! = false
    $withIsReservationComplete: Boolean! = false
    $page: Int
    $pageSize: Int
  ) {
    result: listDeals(filter: $filter, page: $page, pageSize: $pageSize) {
      items {
        ${fragment}
      }
      pagination {
        ${paginationFragment}
      }
    }
  }
`;
