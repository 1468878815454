import React, { useMemo } from 'react';
import { ListGroupItem } from 'reactstrap';
import { BiTrash } from 'react-icons/bi';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'gatsby';

import { DEFAULT_IMAGE, NavigationMainNames } from '../../../../../../../constants';
import { confirmDelete } from '../../../../../../global/confirmAlert';
import { deleteProjectPackageQuery } from '../../../../../../../graphql';
import api from '../../../../../../../api';
import { reloadProjectPackages } from '../../../../../../../store/actions/projectPackageActions';
import NavigationService from '../../../../../../../services/NavigationService';
import './styles.scss';

const DeleteIcon = ({ ...props }) => (
  <span
    role="button"
    aria-hidden
    className="text-danger"
    title={i18n.t('buttons.delete')}
    {...props}
  >
    <BiTrash size="1.1rem" />
  </span>
);

const ListItem = ({ projectPackage, ...props }) => {
  const { package: specPackage } = projectPackage;

  const onDelete = () => {
    const variables = { id: projectPackage.id };
    api.graphql(deleteProjectPackageQuery, variables)
      .then(() => props.reloadProjectPackages(true))
      .catch(() => {});
  };

  const imageUrl = specPackage.imageUrl || DEFAULT_IMAGE;
  const packageUrl = useMemo(() => NavigationService.getNavigationShowUrl(
    projectPackage.package, NavigationMainNames.PACKAGES
  ), [projectPackage]);

  return (
    <ListGroupItem>
      <div className="d-flex align-items-center">
        <div className="pr-3">
          <img src={imageUrl} alt={specPackage.name} className="project-package-image" />
        </div>
        <div className="flex-grow-1 pr-3">
          <Link to={packageUrl}>
            {specPackage.name}
          </Link>
        </div>
        <div>
          <DeleteIcon onClick={() => confirmDelete(onDelete)} />
        </div>
      </div>
    </ListGroupItem>
  );
};

ListItem.propTypes = {
  projectPackage: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect(() => ({}), {
  reloadProjectPackages
})(ListItem);
