import React from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';
import v from 'voca';

import ModalService from '../../../services/ModalService';
import { ModalNames } from '../../../constants';

const TruncateDescription = ({ innerId, description }) => {
  if (v.isBlank(description)) return null;

  const maxLength = 100;

  const isTruncated = (text) => text.length > maxLength;

  const truncateText = (text) => {
    const cleanText = striptags(text);
    const truncated = isTruncated(cleanText);
    return [truncated, truncated ? `${cleanText.slice(0, maxLength)}...` : cleanText];
  };

  const onOpenPopover = () => {
    if (!isTruncated(description)) return;

    ModalService.open(ModalNames.DESCRIPTION_POPOVER, {
      target: innerId,
      description
    });
  };

  return (
    <>
      <div
        id={innerId}
        className="white-space-pre-line"
        onMouseEnter={onOpenPopover}
      >
        <strong>{truncateText(description)}</strong>
      </div>
    </>
  );
};

TruncateDescription.propTypes = {
  innerId: PropTypes.string,
  description: PropTypes.string
};

TruncateDescription.defaultProps = {
  innerId: 'truncate-description',
  description: ''
};

export default TruncateDescription;
