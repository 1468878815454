import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import v from 'voca';

import { formatCurrency } from '../../../../../../utils/currencyUtils';
import { getDealHomeInfo } from '../../../../../../utils/dealUtils';
import DealLastActivityDate from '../../../../../global/Deal/DealLastActivityDate';
import { OfferStatuses } from '../../../../../../constants';
import OfferSteps from '../../../../../global/home/OfferSteps';

const ListItem = ({ deal }) => {
  const { contact: customer, home: savedHome } = deal;
  const home = savedHome || {};
  const { offer } = home;

  if (!offer) {
    home.offer = {
      status: OfferStatuses.INVITED,
      statusChangeDates: {
        invitedAt: deal.createdAt
      }
    };
  }

  const { communityProjectName, name: modelUnitName, address } = getDealHomeInfo(deal);

  return (
    <tr>
      <td className="clickable-column p-0">
        <Link
          to={`/admin/customers/${customer.id}?from=deals`}
          className="d-flex flex-column col-gray-600 p-3 h-100"
        >
          <div className="font-weight-600">{customer.name}</div>
          {!v.isBlank(customer.email) && (
            <div className="col-gray-600">{customer.email}</div>
          )}
        </Link>
      </td>
      <td>
        <OfferSteps home={home} />
      </td>
      <td>
        <div className="font-weight-500 col-gray-900">{formatCurrency(offer?.offerPrice)}</div>
      </td>
      <td>
        <div>
          <div className="text-nowrap font-weight-600 col-gray-800">{modelUnitName}</div>
          {!v.isBlank(address) && (
            <div className="small col-gray-900">{address}</div>
          )}
        </div>
      </td>
      <td>
        <div className="text-nowrap font-weight-500 col-gray-900">{communityProjectName}</div>
      </td>
      <td>
        <DealLastActivityDate deal={deal} />
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  deal: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
