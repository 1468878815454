import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Collapse, FormGroup, Input, Label, Row
} from 'reactstrap';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import classNames from 'classnames';
import i18n from 'i18n-js';
import v from 'voca';

import './styles.scss';
import { EmailServices } from '../../../../../../constants';
import { isInputInvalid } from '../../../../../ui/InputError';
import { InputError } from '../../../../../ui';
import { isMasterAdmin, isTecSupport } from '../../../../../../utils/authUtils';
import EmailMessagesSection from './EmailMessagesSection';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.communications.index' };

const CompanyEmailServices = Object.freeze([
  { label: 'AWS SES', value: EmailServices.AWS_SES },
  { label: 'SendGrid', value: EmailServices.SENDGRID }
]);

const DisclaimerItem = ({ disclaimer, onTextChange, containerId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div id={containerId} className={classNames('disclaimer-container', { opened: isOpen })}>
      <hr />
      <div role="button" aria-hidden onClick={onToggle} className="d-flex align-items-center">
        <div className="flex-grow-1">
          <span className="font-14 font-weight-500">
            {i18n.t(`disclaimerSections.${disclaimer.section}`, i18nOpts)}
          </span>
        </div>
        <div className="expand-icon">
          <span className="plus-icon"><FiPlusCircle size="1.5rem" /></span>
          <span className="minus-icon"><FiMinusCircle size="1.5rem" /></span>
        </div>
      </div>

      <Collapse isOpen={isOpen}>
        <Row>
          <Col md={6} className="pt-3">
            <Input
              type="textarea"
              rows={6}
              name={disclaimer.section}
              id={disclaimer.section}
              value={disclaimer.text}
              onChange={onTextChange}
              maxLength={1250}
              className="font-14 col-gray-600"
            />
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

const Communications = ({ form, error, onTextChange }) => {
  const settings = form.settings || {};
  const disclaimers = settings.disclaimerTexts || [];

  const onChangeDisclaimer = (event) => {
    const { name, value } = event.target;
    const newDisclaimers = disclaimers.some((disclaimerText) => disclaimerText.section === name)
      ? disclaimers.map((disclaimerText) => (disclaimerText.section === name
        ? { ...disclaimerText, text: value }
        : disclaimerText))
      : [...disclaimers, { section: name, text: value }];

    const newSettings = { ...settings, disclaimerTexts: newDisclaimers };
    onTextChange({ target: { name: 'settings', value: newSettings } });
  };

  const onPhoneNumberChange = (event) => {
    let newValue = event.target.value.replaceAll(/\D+/g, '');
    if (!newValue.startsWith('+')) newValue = `+${newValue}`;

    onTextChange({ target: { name: 'senderPhoneNumber', value: newValue } });
  };

  const onPhoneNumberBlur = () => {
    if (form.senderPhoneNumber === '+') onTextChange({ target: { name: 'senderPhoneNumber', value: '' } });
  };

  const getDisclaimer = (section) => {
    const disclaimer = disclaimers.find((disclaimerText) => disclaimerText.section === section);
    return disclaimer || { section, text: '' };
  };

  const isSendGridConnected = useMemo(
    () => !v.isBlank(form.sendGridSettings?.apiKey),
    [form.sendGridSettings]
  );

  const isMasterAdminRole = isMasterAdmin();
  const isTecSupportRole = isTecSupport();
  const canViewMasterSettings = isMasterAdminRole || isTecSupportRole;

  if (!canViewMasterSettings) return null;

  return (
    <>
      {canViewMasterSettings && (
        <>
          <Row>
            <Col md={12} lg={9} xl={8}>
              <FormGroup row id="emailServiceContainer">
                <Label sm={5}>{i18n.t('emailServiceProvider', i18nOpts)}</Label>
                <Col sm={7}>
                  <Input
                    type="select"
                    name="emailService"
                    id="emailService"
                    value={form.emailService || ''}
                    onChange={onTextChange}
                    invalid={isInputInvalid(error, 'emailService')}
                  >
                    {
                      CompanyEmailServices.map((es) => (
                        <option
                          key={`email-service-${es.value}`}
                          value={es.value}
                          disabled={es.value === EmailServices.SENDGRID && !isSendGridConnected}
                        >
                          {es.label}
                        </option>
                      ))
                    }
                  </Input>
                  <InputError name="emailService" error={error} />
                </Col>
              </FormGroup>

              <FormGroup row id="senderEmailContainer">
                <Label sm={5} className="py-0">
                  {i18n.t('sendersEmailAddress', i18nOpts)}
                  <span className="help-text">{i18n.t('helpTexts.sendersEmailAddress', i18nOpts)}</span>
                </Label>
                <Col sm={7}>
                  <Input
                    type="text"
                    name="senderEmail"
                    id="senderEmail"
                    value={form.senderEmail || ''}
                    onChange={onTextChange}
                    invalid={isInputInvalid(error, 'senderEmail')}
                  />
                  <InputError name="senderEmail" error={error} />
                </Col>
              </FormGroup>

              <FormGroup row id="senderPhoneNumberContainer">
                <Label sm={5} className="py-0">
                  {i18n.t('sendersPhoneNumber', i18nOpts)}
                  <span className="help-text">{i18n.t('helpTexts.sendersPhoneNumber', i18nOpts)}</span>
                </Label>
                <Col sm={7}>
                  <Input
                    type="text"
                    name="senderPhoneNumber"
                    id="senderPhoneNumber"
                    value={form.senderPhoneNumber || ''}
                    onChange={onPhoneNumberChange}
                    onBlur={onPhoneNumberBlur}
                    invalid={isInputInvalid(error, 'senderPhoneNumber')}
                  />
                  <InputError name="senderPhoneNumber" error={error} />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <hr className="mt-0" />

          <h4 className="font-weight-500" id="disclaimersSectionContainer">{i18n.t('buildAndPriceDisclaimers', i18nOpts)}</h4>
          <DisclaimerItem disclaimer={getDisclaimer('city')} onTextChange={onChangeDisclaimer} containerId="cityDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('communityProject')} onTextChange={onChangeDisclaimer} containerId="communityProjectDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('lotMap')} onTextChange={onChangeDisclaimer} containerId="lotMapDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('productType')} onTextChange={onChangeDisclaimer} containerId="productTypeDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('modelDetails')} onTextChange={onChangeDisclaimer} containerId="modelDetailsDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('monthlyPaymentDisclaimer')} onTextChange={onChangeDisclaimer} containerId="monthlyPaymentDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('floorplans')} onTextChange={onChangeDisclaimer} containerId="floorplansDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('elevations')} onTextChange={onChangeDisclaimer} containerId="elevationsDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('palettes')} onTextChange={onChangeDisclaimer} containerId="palettesDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('packages')} onTextChange={onChangeDisclaimer} containerId="packagesDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('depositAmount')} onTextChange={onChangeDisclaimer} containerId="depositAmountDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('summary')} onTextChange={onChangeDisclaimer} containerId="summaryDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('exportPagePdf')} onTextChange={onChangeDisclaimer} containerId="exportPagePdfDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('sfPriceDisclaimer')} onTextChange={onChangeDisclaimer} containerId="sfPriceDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('mfPriceDisclaimer')} onTextChange={onChangeDisclaimer} containerId="mfPriceDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('qpDepositInPlace')} onTextChange={onChangeDisclaimer} containerId="qpDepositInPlaceDisclaimerContainer" />
          <DisclaimerItem disclaimer={getDisclaimer('interestRate')} onTextChange={onChangeDisclaimer} containerId="interestRateDisclaimerContainer" />
          <hr className="mt-0" />

          <EmailMessagesSection form={form} onTextChange={onTextChange} />
        </>
      )}
    </>
  );
};

Communications.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.any),
  onTextChange: PropTypes.func
};

Communications.defaultProps = {
  error: null,
  onTextChange: () => {}
};

export default Communications;
