import React from 'react';
import { connect } from 'react-redux';

import MortgageProvidersManagement from '../../../../../global/MortgageProvidersManagement';
import { MortgageProviderAssignableTypes } from '../../../../../../constants';

const MortgageProviders = ({ community }) => (
  <MortgageProvidersManagement
    assignableId={community.id}
    assignableType={MortgageProviderAssignableTypes.COMMUNITY}
  />
);

MortgageProviders.propTypes = {};

MortgageProviders.defaultProps = {};

export default connect((store) => ({
  community: store.communities.community,
}))(MortgageProviders);
