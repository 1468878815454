import React from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import useHomeRental from '../../../../../../../hooks/useHomeRental';
import useIsVerifiedEnabled from '../../../../../../../hooks/useIsVerifiedEnabled';
import ListItem from './ListItem';

const i18nOpts = { scope: 'components.admin.units.show.followers.components.followersList.index' };

const FollowersList = ({ followers, isStartedReservationList }) => {
  const isVerifiedEnabled = useIsVerifiedEnabled();
  const { isRental } = useHomeRental();

  return (
    <Table bordered responsive className="mb-4">
      <thead>
        <tr>
          <th>{i18n.t('list.name', i18nOpts)}</th>
          <th>{i18n.t('list.phone', i18nOpts)}</th>
          {isVerifiedEnabled && (
            <th>{i18n.t(`list.${isRental ? 'rentingPower' : 'buyingPower'}`, i18nOpts)}</th>
          )}
          <th>{i18n.t('list.browsingPrice', i18nOpts)}</th>
          <th>{i18n.t(`list.${isStartedReservationList ? 'startedDate' : 'savedDate'}`, i18nOpts)}</th>
          <th>{i18n.t('list.lastActive', i18nOpts)}</th>
        </tr>
      </thead>
      <tbody>
        {
          followers.map((observer) => (
            <ListItem key={`observer-${observer.id}`} observer={observer} isStartedReservationList={isStartedReservationList} />
          ))
        }
      </tbody>
    </Table>
  );
};

FollowersList.propTypes = {
  followers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isStartedReservationList: PropTypes.bool
};

FollowersList.defaultProps = {
  followers: [],
  isStartedReservationList: false
};

export default FollowersList;
