import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import i18n from 'i18n-js';

import useWindowDimensions from '../../../../../../hooks/useWindowDimensions';
import { isMobile } from '../../../../../../utils/windowUtils';

const DetailsButton = () => {
  const { width } = useWindowDimensions();

  const toggleCustomerContainer = (show) => {
    document.getElementById('two-column-layout').classList.toggle('show-left-container', show);
  };

  const onView = () => {
    toggleCustomerContainer(true);
  };

  useEffect(() => {
    if (isMobile()) return;

    toggleCustomerContainer(false);
  }, [width]);

  return (
    <Button color="secondary" outline onClick={onView}>
      {i18n.t('buttons.details')}
    </Button>
  );
};

DetailsButton.propTypes = {};

DetailsButton.defaultProps = {};

export default DetailsButton;
