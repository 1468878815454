import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import CustomerName from '../../../../../global/customer/CustomerName';
import ActiveHomeInfo from './ActiveHomeInfo';

const ListItem = ({ customer }) => (
  <tr>
    <td className="clickable-column p-0">
      <Link to={`/admin/customers/${customer.id}`} className="col-gray-600 p-3 d-block">
        <CustomerName customer={customer} />
        <ActiveHomeInfo home={customer.activeHome} />
      </Link>
    </td>
  </tr>
);

ListItem.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
