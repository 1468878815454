import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import _ from 'lodash';

import { fetchBuildingModelsBedroomsAsync } from './utils';
import CollapsibleFilterGroup from '../../../../../../common/CollapsibleFilterGroup';
import ShowMoreContent from '../../../../../../common/ShowMoreContent';
import ListValue from '../../../../../../global/filters/components/ListValue';

const i18nOpts = { scope: 'components.admin.units.list.components.filtersModal.bedsFilter.index' };

const BedsFilter = ({ filters, onChange, currentCompany }) => {
  const bedrooms = filters.bedrooms || [];
  const [availableBedrooms, setAvailableBedrooms] = useState([]);
  const [loading, setLoading] = useState(false);

  const onToggle = (event) => {
    const { value } = event.target;
    onChange({ bedrooms: _.xor(bedrooms, [Number(value)]) });
  };

  const loadBuildingModelsBedrooms = () => {
    fetchBuildingModelsBedroomsAsync(currentCompany.id)
      .then((result) => {
        const uniqSortedBedrooms = [...new Set(result)].sort();
        setAvailableBedrooms(uniqSortedBedrooms);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadBuildingModelsBedrooms();
  }, []);

  const filtersCount = useMemo(() => bedrooms.length, [filters]);

  if (loading || !availableBedrooms.length) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <ShowMoreContent
        items={availableBedrooms}
        renderItem={(bedroom) => (
          <ListValue
            key={`bedroom-${bedroom}`}
            label={i18n.t('beds', { ...i18nOpts, count: bedroom })}
            value={bedroom}
            selectedValues={bedrooms}
            onChange={onToggle}
          />
        )}
        showMoreButtonExtraClass="mt-2"
      />
    </CollapsibleFilterGroup>
  );
};

BedsFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

BedsFilter.defaultProps = {
  onChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
}))(BedsFilter);
