import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';

import Loading from '../../../ui/Loading';
import TwoColumnLayout from '../../../common/TwoColumnLayout';
import { getCommunity } from '../../../../store/actions/communityActions';
import NavigationService from '../../../../services/NavigationService';
import { NavigationMainNames } from '../../../../constants';
import Summary from './Summary';
import Sections from './Sections';
import DescriptionPopover from '../../../common/TruncateDescription/DescriptionPopover';
import DirectLinkModal from '../../../global/DirectLinkModal';
import QRCodeModal from '../../../global/QRCodeModal';

const Show = ({ id, community, ...props }) => {
  const [mounted, setMounted] = useState(false);
  const communityId = parseInt(id, 10);

  const loadCommunity = () => {
    setMounted(false);

    props.getCommunity(communityId)
      .then(({ value: { data: { item } } }) => {
        setMounted(true);
        NavigationService.addItem(item, NavigationMainNames.COMMUNITIES);
      })
      .catch(() => navigate('/admin/communities'));
  };

  useEffect(() => {
    loadCommunity();
  }, [id]);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={community.name} />

      <TwoColumnLayout
        leftComponent={<Summary />}
        rightComponent={<Sections />}
      />

      <DescriptionPopover />
      <DirectLinkModal />
      <QRCodeModal />
    </div>
  );
};

Show.propTypes = {};

Show.defaultProps = {};

export default connect((store) => ({
  community: store.communities.community,
}), {
  getCommunity
})(Show);
