import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import i18n from 'i18n-js';
import { Link } from 'gatsby';
import { connect } from 'react-redux';

import { isAdmin } from '../../../../utils/authUtils';
import { setUnitsViewMode, setUnitColumns } from '../../../../store/actions/unitActions';
import { ViewModes } from '../../../../constants';
import ViewModesDropdown from '../../../global/ViewModesDropdown';
import Loading from '../../../ui/Loading';
import SearchBar from '../../../common/SearchBar';
import { Tabs } from './utils';
import FiltersButton from './components/FiltersButton';
import FiltersModal from './components/FiltersModal';
import EditColumnsButton from './components/EditColumnsButton';
import ColumnsPickerModal from './components/ColumnsPickerModal';
import ActiveListings from './ActiveListings';
import InactiveListings from './InactiveListings';

const i18nOpts = { scope: 'components.admin.units.list.index' };

const List = ({ viewMode, currentUser, ...props }) => {
  const [activeTab, setActiveTab] = useState(Tabs.ACTIVE_LISTINGS);

  const [mounted, setMounted] = useState(false);
  const [query, setQuery] = useState(null);
  const [initialColumnsSet, setInitialColumnsSet] = useState(false);

  const onSearch = (e, q) => {
    e.preventDefault();

    setQuery(q);
  };

  const onChangeViewMode = (newMode) => {
    props.setUnitsViewMode(newMode);
  };

  const setInitialColumns = () => {
    const { units: initialColumns = [] } = currentUser?.columnSettings || {};
    if (initialColumns && initialColumns.length) props.setUnitColumns(initialColumns);
    setInitialColumnsSet(true);
  };

  useEffect(() => {
    if (initialColumnsSet) setMounted(true);
  }, [initialColumnsSet]);

  useEffect(() => {
    setInitialColumns();
  }, []);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="mb-4">
        <div className="font-24">{i18n.t('title', i18nOpts)}</div>
      </div>

      <div className="d-flex mb-4 align-items-center flex-wrap gap-3">
        <div className="flex-grow-1">
          <SearchBar
            onSubmit={onSearch}
            inputProps={{ bsSize: 'md', placeholder: i18n.t('searchBarPlaceholder', i18nOpts) }}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center flex-wrap gap-3">
          <ViewModesDropdown viewMode={viewMode} onChange={onChangeViewMode} />

          {viewMode === ViewModes.LIST && <EditColumnsButton />}

          <FiltersButton />

          {isAdmin() && (
            <Link to="/admin/units/form" className="btn btn-primary">
              <i className="fas fa-plus mr-2" />
              {i18n.t('buttons.add', i18nOpts)}
            </Link>
          )}
        </div>
      </div>

      <Nav tabs>
        <NavItem>
          <NavLink
            active={activeTab === Tabs.ACTIVE_LISTINGS}
            onClick={() => setActiveTab(Tabs.ACTIVE_LISTINGS)}
          >
            {i18n.t('tabs.activeListings', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === Tabs.INACTIVE_LISTINGS}
            onClick={() => setActiveTab(Tabs.INACTIVE_LISTINGS)}
          >
            {i18n.t('tabs.inactiveListings', i18nOpts)}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.ACTIVE_LISTINGS}>
          <ActiveListings query={query} initialColumnsSet={initialColumnsSet} />
        </TabPane>
        <TabPane tabId={Tabs.INACTIVE_LISTINGS}>
          <InactiveListings query={query} initialColumnsSet={initialColumnsSet} />
        </TabPane>
      </TabContent>

      <ColumnsPickerModal />
      <FiltersModal />
    </div>
  );
};

export default connect((store) => ({
  viewMode: store.units.viewMode,
  currentUser: store.users.currentUser
}), {
  setUnitsViewMode,
  setUnitColumns
})(List);
