import React from 'react';
import { connect } from 'react-redux';

import { SalableTypes } from '../../../../../../constants';
import SellersManagement from '../../../../../global/SellersManagement';

const SalesRepresentatives = ({ community }) => (
  <div>
    <SellersManagement salableId={community.id} salableType={SalableTypes.COMMUNITY} />
  </div>
);

SalesRepresentatives.propTypes = {};

SalesRepresentatives.defaultProps = {};

export default connect((store) => ({
  community: store.communities.community,
}))(SalesRepresentatives);
