import React from 'react';
import PropTypes from 'prop-types';
import { BiCloudDownload } from 'react-icons/bi';
import i18n from 'i18n-js';
import v from 'voca';

import TimeLineElement from '../../../../common/TimeLineElement';
import ActivityDate from '../components/ActivityDate';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.downloadContent.index' };

const DownloadContent = ({ activity, innerKey }) => {
  const { trackingEvent: { data } } = activity;

  return (
    <TimeLineElement
      innerKey={innerKey}
      showDate={activity.showDate}
      date={activity.createdAt}
      Icon={<BiCloudDownload />}
    >
      <div className="d-flex align-items-start gap-3">
        <div className="activity-timeline-title">{i18n.t('title', i18nOpts)}</div>
        <ActivityDate date={activity.createdAt} />
      </div>
      <div>
        {!v.isBlank(data?.url) && (
          <a href={data.url} target="_blank" rel="noreferrer">{data.url}</a>
        )}
      </div>
    </TimeLineElement>
  );
};

DownloadContent.propTypes = {
  activity: PropTypes.objectOf(PropTypes.any).isRequired,
  innerKey: PropTypes.string.isRequired
};

DownloadContent.defaultProps = {};

export default DownloadContent;
