import i18n from 'i18n-js';

import { Tabs } from '../../utils';
import { isMasterAdmin, isTecSupport } from '../../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.communications' };

export function getSettings() {
  const settings = [];

  if (isMasterAdmin() || isTecSupport()) {
    settings.push(...[
      {
        id: 'emailService',
        label: i18n.t('index.emailServiceProvider', i18nOpts),
        containerId: 'emailServiceContainer'
      },
      {
        id: 'senderEmail',
        label: i18n.t('index.sendersEmailAddress', i18nOpts),
        containerId: 'senderEmailContainer'
      },
      {
        id: 'senderPhoneNumber',
        label: i18n.t('index.sendersPhoneNumber', i18nOpts),
        containerId: 'senderPhoneNumberContainer'
      },
      {
        id: 'disclaimersSection',
        label: i18n.t('index.buildAndPriceDisclaimers', i18nOpts),
        containerId: 'disclaimersSectionContainer'
      },
      {
        id: 'cityDisclaimer',
        label: i18n.t('index.disclaimerSections.city', i18nOpts),
        containerId: 'cityDisclaimerContainer'
      },
      {
        id: 'communityProjectDisclaimer',
        label: i18n.t('index.disclaimerSections.communityProject', i18nOpts),
        containerId: 'communityProjectDisclaimerContainer'
      },
      {
        id: 'lotMapDisclaimer',
        label: i18n.t('index.disclaimerSections.lotMap', i18nOpts),
        containerId: 'lotMapDisclaimerContainer'
      },
      {
        id: 'productTypeDisclaimer',
        label: i18n.t('index.disclaimerSections.productType', i18nOpts),
        containerId: 'productTypeDisclaimerContainer'
      },
      {
        id: 'modelDetailsDisclaimer',
        label: i18n.t('index.disclaimerSections.modelDetails', i18nOpts),
        containerId: 'modelDetailsDisclaimerContainer'
      },
      {
        id: 'monthlyPaymentDisclaimer',
        label: i18n.t('index.disclaimerSections.monthlyPaymentDisclaimer', i18nOpts),
        containerId: 'monthlyPaymentDisclaimerContainer'
      },
      {
        id: 'floorplansDisclaimer',
        label: i18n.t('index.disclaimerSections.floorplans', i18nOpts),
        containerId: 'floorplansDisclaimerContainer'
      },
      {
        id: 'elevationsDisclaimer',
        label: i18n.t('index.disclaimerSections.elevations', i18nOpts),
        containerId: 'elevationsDisclaimerContainer'
      },
      {
        id: 'palettesDisclaimer',
        label: i18n.t('index.disclaimerSections.palettes', i18nOpts),
        containerId: 'palettesDisclaimerContainer'
      },
      {
        id: 'packagesDisclaimer',
        label: i18n.t('index.disclaimerSections.packages', i18nOpts),
        containerId: 'packagesDisclaimerContainer'
      },
      {
        id: 'depositAmountDisclaimer',
        label: i18n.t('index.disclaimerSections.depositAmount', i18nOpts),
        containerId: 'depositAmountDisclaimerContainer'
      },
      {
        id: 'summaryDisclaimer',
        label: i18n.t('index.disclaimerSections.summary', i18nOpts),
        containerId: 'summaryDisclaimerContainer'
      },
      {
        id: 'exportPagePdfDisclaimer',
        label: i18n.t('index.disclaimerSections.exportPagePdf', i18nOpts),
        containerId: 'exportPagePdfDisclaimerContainer'
      },
      {
        id: 'sfPriceDisclaimer',
        label: i18n.t('index.disclaimerSections.sfPriceDisclaimer', i18nOpts),
        containerId: 'sfPriceDisclaimerContainer'
      },
      {
        id: 'mfPriceDisclaimer',
        label: i18n.t('index.disclaimerSections.mfPriceDisclaimer', i18nOpts),
        containerId: 'mfPriceDisclaimerContainer'
      },
      {
        id: 'qpDepositInPlaceDisclaimer',
        label: i18n.t('index.disclaimerSections.qpDepositInPlace', i18nOpts),
        containerId: 'qpDepositInPlaceDisclaimerContainer'
      },
      {
        id: 'interestRateDisclaimer',
        label: i18n.t('index.disclaimerSections.interestRate', i18nOpts),
        containerId: 'interestRateDisclaimerContainer'
      },
      {
        id: 'emailMessagesSection',
        label: i18n.t('emailMessagesSection.index.title', i18nOpts),
        containerId: 'emailMessagesSectionContainer'
      },
      {
        id: 'savedHomeReservationReminderQPEmailMessage',
        label: i18n.t('emailMessagesSection.index.sections.savedHomeReservationReminderQP', i18nOpts),
        containerId: 'savedHomeReservationReminderQPEmailMessageContainer'
      },
      {
        id: 'savedHomeReservationReminderUnitEmailMessage',
        label: i18n.t('emailMessagesSection.index.sections.savedHomeReservationReminderUnit', i18nOpts),
        containerId: 'savedHomeReservationReminderUnitEmailMessageContainer'
      }
    ]);
  }

  return settings.map((setting) => ({
    ...setting,
    tabId: Tabs.COMMUNICATIONS
  }));
}
