import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

import { getQuickPossessionStatusName } from '../../../../../../../../../utils/enumUtils';

const Status = ({ quickPossession }) => {
  const { status } = quickPossession;

  if (v.isBlank(status)) return null;

  return (
    <div>
      <span className={`badge text-white status-badge ${v.slugify(status)}`}>
        {getQuickPossessionStatusName(status)}
      </span>
    </div>
  );
};

Status.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

Status.defaultProps = {};

export default Status;
