import React from 'react';
import { connect } from 'react-redux';
import Switch from 'rc-switch';
import i18n from 'i18n-js';
import v from 'voca';

import { Statuses } from '../../../../../../constants';
import { getCreatorDisplayName, getLastModifierDisplayName } from '../../../../../../utils/auditUtils';
import TruncateDescription from '../../../../../common/TruncateDescription';
import { setProjectAttributes } from '../../../../../../store/actions/projectActions';
import { getProjectAvailabilityName } from '../../../../../../utils/projectUtils';
import { isAdmin } from '../../../../../../utils/authUtils';
import { getProjectBuildAndPriceStartingPointName } from '../../../../../../utils/enumUtils';
import { updateProjectStatusAsync } from './utils';
import { confirmAlert } from '../../../../../global/confirmAlert';

const i18nOpts = { scope: 'components.admin.projects.show.summary.details.index' };

const Details = ({ project, ...props }) => {
  if (!project) return null;

  const updateProjectStatus = (isPublished) => {
    const input = { id: project.id, status: isPublished ? Statuses.PUBLISHED : Statuses.DRAFT };

    updateProjectStatusAsync(input)
      .then((item) => {
        props.setProjectAttributes(project.id, { ...item });
      })
      .catch(() => {});
  };

  const onTogglePublished = (checked) => {
    const message = i18n.t(`messages.${checked ? 'publish' : 'unpublish'}`, { itemName: project.name });
    const options = {
      title: null,
      message: `<div class="font-18">${message}</div>`
    };
    confirmAlert(() => updateProjectStatus(checked), options);
  };

  const creatorName = getCreatorDisplayName(project);
  const lastModifierName = getLastModifierDisplayName(project);

  return (
    <div className="d-flex flex-column gap-3">
      <div>
        <h2 className="mb-0">{project.name}</h2>
      </div>

      <div className="d-flex align-items-center">
        <div><strong>{i18n.t('statuses.published')}</strong></div>
        <div className="ml-auto">
          <Switch checked={project.status === Statuses.PUBLISHED} onChange={onTogglePublished} />
        </div>
      </div>

      {!v.isBlank(project.imageUrl) && (
        <img src={project.imageUrl} alt={project.name} className="w-100" />
      )}

      {!v.isBlank(project.hashId) && (
        <div>
          <div className="label">{i18n.t('projectId', i18nOpts)}</div>
          <div><strong>{project.hashId}</strong></div>
        </div>
      )}

      {project.city && (
        <>
          <div>
            <div className="label">{i18n.t('city', i18nOpts)}</div>
            <div><strong>{project.city.name}</strong></div>
          </div>

          {!v.isBlank(project.city.state) && (
            <div>
              <div className="label">{i18n.t('province', i18nOpts)}</div>
              <div><strong>{project.city.state}</strong></div>
            </div>
          )}
        </>
      )}

      {!v.isBlank(project.subRegion) && (
        <div>
          <div className="label">{i18n.t('subRegion', i18nOpts)}</div>
          <div><strong>{project.subRegion}</strong></div>
        </div>
      )}

      {!v.isBlank(project.address) && (
        <div>
          <div className="label">{i18n.t('address', i18nOpts)}</div>
          <div className="white-space-pre-line"><strong>{project.address}</strong></div>
        </div>
      )}

      {!v.isBlank(project.buildingType) && (
        <div>
          <div className="label">{i18n.t('buildingType', i18nOpts)}</div>
          <div><strong>{project.buildingType}</strong></div>
        </div>
      )}

      <div>
        <div className="label">{i18n.t('availability', i18nOpts)}</div>
        <div>
          <strong>{getProjectAvailabilityName(project.availability)}</strong>
        </div>
      </div>

      <div>
        <div className="label">{i18n.t('type', i18nOpts)}</div>
        <div>
          <strong>{i18n.t(`types.${project.type.toLowerCase()}`, i18nOpts)}</strong>
        </div>
      </div>

      {!v.isBlank(project.externalId) && (
        <div>
          <div className="label">{i18n.t('externalId', i18nOpts)}</div>
          <div><strong>{project.externalId}</strong></div>
        </div>
      )}

      {!v.isBlank(project.description) && (
        <div>
          <div className="label">{i18n.t('description', i18nOpts)}</div>
          <TruncateDescription innerId="project-truncate-description" description={project.description} />
        </div>
      )}

      {!v.isBlank(project.longDescription) && (
        <div>
          <div className="label">{i18n.t('longDescription', i18nOpts)}</div>
          <TruncateDescription innerId="project-truncate-long-description" description={project.longDescription} />
        </div>
      )}

      {!v.isBlank(project.websiteUrl) && (
        <div>
          <div className="label">{i18n.t('websiteUrl', i18nOpts)}</div>
          <div><strong>{project.websiteUrl}</strong></div>
        </div>
      )}

      <div>
        <div className="label">{i18n.t('flowForBuildAndPrice', i18nOpts)}</div>
        <div>
          <strong>
            {getProjectBuildAndPriceStartingPointName(project.buildAndPriceStartingPoint)}
          </strong>
        </div>
      </div>

      {isAdmin() && (
        <>
          {!v.isBlank(creatorName) && (
            <div>
              <div className="label">{i18n.t('createdBy', i18nOpts)}</div>
              <div><strong>{creatorName}</strong></div>
            </div>
          )}

          {!v.isBlank(lastModifierName) && (
            <div>
              <div className="label">{i18n.t('lastModifiedBy', i18nOpts)}</div>
              <div><strong>{lastModifierName}</strong></div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

Details.propTypes = {};

Details.defaultProps = {};

export default connect((store) => ({
  project: store.projects.project
}), {
  setProjectAttributes
})(Details);
