import React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import PropTypes from 'prop-types';

import { Tabs } from '../utils';
import CompanyAndBranding from './CompanyAndBranding';
import SiteAndUX from './SiteAndUX';
import SalesConfig from './SalesConfig';
import Workflow from './Workflow';
import Communications from './Communications';
import AdvancedSettings from './AdvancedSettings';
import Contract from './Contract';

const TabsContent = ({
  activeTab, form, error, onTextChange
}) => (
  <TabContent activeTab={activeTab} className="pt-4">
    <TabPane tabId={Tabs.COMPANY_AND_BRANDING}>
      <CompanyAndBranding form={form} error={error} onTextChange={onTextChange} />
    </TabPane>
    <TabPane tabId={Tabs.SITE_AND_UX}>
      <SiteAndUX form={form} error={error} onTextChange={onTextChange} />
    </TabPane>
    <TabPane tabId={Tabs.SALES_CONFIG}>
      <SalesConfig form={form} error={error} onTextChange={onTextChange} />
    </TabPane>
    <TabPane tabId={Tabs.WORKFLOW}>
      <Workflow form={form} error={error} onTextChange={onTextChange} />
    </TabPane>
    <TabPane tabId={Tabs.COMMUNICATIONS}>
      <Communications form={form} error={error} onTextChange={onTextChange} />
    </TabPane>
    <TabPane tabId={Tabs.ADVANCED_SETTINGS}>
      <AdvancedSettings form={form} error={error} onTextChange={onTextChange} />
    </TabPane>
    <TabPane tabId={Tabs.CONTRACT}>
      <Contract form={form} error={error} onTextChange={onTextChange} />
    </TabPane>
  </TabContent>
);

TabsContent.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(Tabs)).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.any),
  onTextChange: PropTypes.func
};

TabsContent.defaultProps = {
  error: null,
  onTextChange: () => {}
};

export default TabsContent;
