import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Loading from '../../../../ui/Loading';
import ListEmpty from '../../../../common/ListEmpty';
import { ViewModes } from '../../../../../constants';
import GridView from '../components/GridView';
import ListView from '../components/ListView';
import ListPagination from '../../../../common/ListPagination';
import { fetchUnitsAsync, getUnitFilters } from '../utils';

const ActiveListings = ({
  currentCompany, viewMode, columns, filters, query, initialColumnsSet
}) => {
  const [units, setUnits] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadUnits = (page = 1) => {
    setLoading(true);

    const filter = getUnitFilters(currentCompany, filters, query, false);

    const variables = { filter, page };
    fetchUnitsAsync(variables)
      .then(({ items, pagination: currentPagination }) => {
        setUnits(items);
        setPagination(currentPagination);
      })
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  };

  const onChangePage = (page) => {
    loadUnits(page);
  };

  useEffect(() => {
    if (initialColumnsSet) loadUnits();
  }, [filters, columns, query, initialColumnsSet]);

  if (loading) return <Loading loading />;

  return (
    <>
      <ListEmpty loading={loading} items={units} />

      {units.length > 0 && (
        <div className="mb-4">
          {viewMode === ViewModes.GRID ? <GridView units={units} /> : <ListView units={units} />}
        </div>
      )}

      <div className="text-right mt-4">
        <ListPagination pagination={pagination} onPress={onChangePage} />
      </div>
    </>
  );
};

ActiveListings.propTypes = {
  initialColumnsSet: PropTypes.bool,
  query: PropTypes.string
};

ActiveListings.defaultProps = {
  initialColumnsSet: false,
  query: ''
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  viewMode: store.units.viewMode,
  columns: store.units.columns,
  filters: store.units.filters,
}))(ActiveListings);
