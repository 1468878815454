import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

import ListItem from './ListItem';

const ListView = ({ customers }) => (
  <Table>
    <tbody>
      {
        customers.map((customer) => (
          <ListItem key={`customer-${customer.id}`} customer={customer} />
        ))
      }
    </tbody>
  </Table>
);

ListView.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
};

ListView.defaultProps = {
  customers: []
};

export default ListView;
