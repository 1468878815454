import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import ListItem from './ListItem';

const GridView = ({ units }) => {
  if (!units.length) return null;

  return (
    <Row>
      {
        units.map((u) => (
          <Col key={`unit-${u.id}`} xs="12" sm="6" md="6" lg="4" className="mb-4">
            <ListItem unit={u} />
          </Col>
        ))
      }
    </Row>
  );
};

GridView.propTypes = {
  units: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
};

GridView.defaultProps = {
  units: []
};

export default GridView;
