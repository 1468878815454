import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/all';
import classNames from 'classnames';

import styles from './MetricCard.module.scss';
import { formatNumber, isValidAmount } from '../../../../../utils/currencyUtils';

const MetricCard = ({
  title, value, percentageChange
}) => {
  const isSignificantPositive = percentageChange >= 20;
  const isSignificantNegative = percentageChange <= -20;

  return (
    <Card
      body
      className={classNames('h-100 mb-0 p-3', styles.card, {
        [styles.positiveChange]: isSignificantPositive,
        [styles.negativeChange]: isSignificantNegative
      })}
    >
      <div className="font-weight-600 mb-1">{ title }</div>
      <div className="d-flex flex-wrap">
        <div>
          <h3 className="mb-0">{formatNumber(value)}</h3>
        </div>
        {isValidAmount(percentageChange) && (
          <>
            {percentageChange > 0 && (
              <div className={classNames('ml-auto d-flex', { 'col-success': isSignificantPositive })}>
                <div><FaChevronUp size="0.625rem" /></div>
                <div className="ml-1 align-self-center">
                  <small className="font-weight-500">{`${Math.abs(percentageChange)}%`}</small>
                </div>
              </div>
            )}

            {percentageChange < 0 && (
              <div className={classNames('ml-auto d-flex', { 'col-danger': isSignificantNegative })}>
                <div><FaChevronDown size="0.625rem" /></div>
                <div className="ml-1 align-self-center">
                  <small className="font-weight-500">{`${Math.abs(percentageChange)}%`}</small>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  );
};

MetricCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  percentageChange: PropTypes.number
};

MetricCard.defaultProps = {
  value: 0,
  percentageChange: 0
};

export default MetricCard;
