import React, { useMemo } from 'react';
import { ListGroupItem } from 'reactstrap';
import { BiTrash } from 'react-icons/bi';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'gatsby';

import { DEFAULT_IMAGE, NavigationMainNames } from '../../../../../../../constants';
import { confirmDelete } from '../../../../../../global/confirmAlert';
import { deleteProjectPaletteQuery } from '../../../../../../../graphql';
import api from '../../../../../../../api';
import { reloadProjectPalettes } from '../../../../../../../store/actions/projectPaletteActions';
import NavigationService from '../../../../../../../services/NavigationService';
import './styles.scss';

const DeleteIcon = ({ ...props }) => (
  <span
    role="button"
    aria-hidden
    className="text-danger"
    title={i18n.t('buttons.delete')}
    {...props}
  >
    <BiTrash size="1.1rem" />
  </span>
);

const ListItem = ({ projectPalette, ...props }) => {
  const { palette } = projectPalette;

  const onDelete = () => {
    const variables = { id: projectPalette.id };
    api.graphql(deleteProjectPaletteQuery, variables)
      .then(() => props.reloadProjectPalettes(true))
      .catch(() => {});
  };

  const imageUrl = palette.defaultImageUrl || DEFAULT_IMAGE;
  const paletteUrl = useMemo(() => NavigationService.getNavigationShowUrl(
    palette, NavigationMainNames.PALETTES
  ), [projectPalette]);

  return (
    <ListGroupItem>
      <div className="d-flex align-items-center">
        <div className="pr-3">
          <img src={imageUrl} alt={palette.name} className="project-palette-image" />
        </div>
        <div className="flex-grow-1 pr-3">
          <Link to={paletteUrl}>
            {palette.name}
          </Link>
        </div>
        <div>
          <DeleteIcon onClick={() => confirmDelete(onDelete)} />
        </div>
      </div>
    </ListGroupItem>
  );
};

ListItem.propTypes = {
  projectPalette: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect(() => ({}), {
  reloadProjectPalettes
})(ListItem);
