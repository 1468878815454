const fragment = `
  id
  startDate
  renewalDate
  monthlyAmount
  type
  stage
`;

export const saveCompanyContractMutation = `
  mutation SaveCompanyContract($input: CreateCompanyContractInput!) {
    item: saveCompanyContract(input: $input) {
      ${fragment}
    }
  }
`;
