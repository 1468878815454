import React from 'react';
import i18n from 'i18n-js';
import { Button, Card, Table } from 'reactstrap';

import CopyLink from '../../../../../common/CopyLink';

const i18nOpts = { scope: 'components.admin.integrations.embedWidget.buildHomeSetup.gatedPopUp.index' };

const GATED_POP_UP_CODE = `
  <a class="ownly-gated-content" href="/images/myw3schoolsimage.jpg" download>
    <img src="/images/myw3schoolsimage.jpg" alt="W3Schools">
  </a>
`;

const OTHER_ATTRIBUTES = Object.freeze([
  {
    attribute: 'data-community-id', type: 'string', description: 'Community ID. <br/> For example <code>DamLQeRx</code>',
  },
  {
    attribute: 'data-model-id', type: 'string', description: 'Model ID. <br/> For example <code>XBmxAeYV</code>',
  }
]);

const GatedPopUp = () => (
  <div>
    <h2>{i18n.t('title', i18nOpts)}</h2>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('getVerifiedDesc', i18nOpts) }} />
    <p>{i18n.t('example', i18nOpts)}</p>

    <Card body className="d-inline-block">
      <pre>
        {/* eslint-disable-next-line max-len */}
        &lt;a class=&quot;ownly-gated-content&quot; href=&quot;/images/myw3schoolsimage.jpg&quot; download&gt;
        <br />
        {/* eslint-disable-next-line max-len */}
        &nbsp;&nbsp;&lt;img src=&quot;/images/myw3schoolsimage.jpg&quot; alt=&quot;W3Schools&quot;&gt;
        <br />
        &lt;/a&gt;
      </pre>
      <CopyLink text={GATED_POP_UP_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>

    <p>{i18n.t('otherAttributes', i18nOpts)}</p>
    <div className="table-responsive">
      <Table bordered>
        <thead>
          <tr>
            <th>{i18n.t('list.attribute', i18nOpts)}</th>
            <th>{i18n.t('list.type', i18nOpts)}</th>
            <th>{i18n.t('list.description', i18nOpts)}</th>
          </tr>
        </thead>
        <tbody>
          {
            OTHER_ATTRIBUTES.map((field) => (
              <tr key={field.attribute}>
                <td><code>{field.attribute}</code></td>
                <td><i>{field.type}</i></td>
                <td>
                  <div dangerouslySetInnerHTML={{ __html: field.description }} />
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  </div>
);

GatedPopUp.propTypes = {};

GatedPopUp.defaultProps = {};

export default GatedPopUp;
