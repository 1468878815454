import { trackingEventFragment } from './fragments/trackingEvents';
import { paginationFragment } from './fragments/pagination';

const fragment = `
  contactId
  type
  createdAt
  visitorSession {
    id
    activeTime
    referer
    trackingEvents {
      ${trackingEventFragment}
    }
    trafficSourceType
    trafficSourceData
    visitor {
      id
      trackingSource
    }
    isExternalReferer
  }
  tour {
    type
    rawData {
      tour {
        date
        division
        community
        plan
        homesite
        address
        city
        stateProvince
        postalCode
        tourReason
      }
    }
  }
  home {
    id
    beds
    baths
    sizeSqft
    totalPrice
    homeUrl
    createdAt
    deletedByUserAt
    singleFamilyHome {
      isQuickPossession
      isLot
      lot
      model {
        id
        name
        imageUrl
        defaultImageUrl
        productType {
          id
          name
        }
      }
      community {
        id
        name
      }
      elevation {
        id
        imageUrl
      }
    }
    multiFamilyHome {
      project {
        id
        name
      }
      unit {
        id
        name
        defaultImageUrl
        buildingModel {
          id
          name
          bedrooms
          bathrooms
          size
        }
      }
    }
  }
  homewisePostback {
    id
    rawData {
      status
      id
      updatedAt
      price
    }
    createdAt
  }
  message {
    id
    message
  }
  formSubmission {
    id
    formData {
      name
      value
    }
    form {
      name
      url
      mappedFields
    }
  }
  onfidoCheck {
    checkReport 
  }
  customerInvitation {
    id
    invitationType
    inviter {
      id
      firstName
    }
    home {
      __typename
      ... on QuickPossession {
        id
        hashId
        totalPrice
        customPrice
        imageUrl
        deletedAt
        community {
          id
          name
        }
        model {
          id
          name
        }
      }
      ... on Unit {
        id
        hashId
        name
        price
        defaultImageUrl
        deletedAt
        buildingModel {
          id
          name
          project {
            id
            name
          }
        }
      }
    }
  }
  refund {
    id
    amount
    refundedByUser {
      id
      name
    }
  }
  contact {
    id
    name
  }
  trackingEvent {
    id
    eventType
    data
  }
`;

export const listActivitiesQuery = `
  query ListActivities(
    $filter: ActivityFilterInput,
    $page: Int,
    $pageSize: Int
  ) {
    result: listActivities(filter: $filter, page: $page, pageSize: $pageSize) {
      items {
        ${fragment}
      }
      pagination {
        ${paginationFragment}
      }
    }
  }
`;
