import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import useValidSelectedColumns from '../../../hooks/useValidSelectedColumns';
import CustomColumnValue from './CustomColumnValue';
import { isAdmin } from '../../../../../../../utils/authUtils';
import ActionsMenu from '../../ActionsMenu';

const ListItem = ({ unit }) => {
  const columns = useValidSelectedColumns();

  const onClick = (event) => {
    const unitPath = `/admin/units/${unit.id}`;

    if (event.metaKey || event.ctrlKey) window.open(unitPath, '_blank');
    else navigate(unitPath);
  };

  const onActionsTdClick = (event) => {
    event.stopPropagation();
  };

  return (
    <tr role="button" aria-hidden onClick={onClick} className="clickable-row">
      {
        columns.map((column) => (
          <td key={`unit-cell-${unit.id}-${column}`}>
            <CustomColumnValue column={column} unit={unit} />
          </td>
        ))
      }

      {isAdmin() && (
        <td className="text-right p-0">
          <div role="button" aria-hidden onClick={onActionsTdClick} className="p-3">
            <ActionsMenu unit={unit} />
          </div>
        </td>
      )}
    </tr>
  );
};

ListItem.propTypes = {
  unit: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
