import React, { useMemo } from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import { navigate } from 'gatsby';
import i18n from 'i18n-js';

import { getParam } from '../../../../../../utils/paramsUtils';

const BackLink = () => {
  const redirectUrl = useMemo(() => {
    const from = getParam('from');

    switch (from) {
      case 'dashboard':
        return '/admin/overview';
      default:
        return '/admin/communities';
    }
  }, []);

  const onClick = () => {
    navigate(redirectUrl);
  };

  return (
    <div className="d-inline-flex align-items-center text-primary" role="button" aria-hidden onClick={onClick}>
      <MdOutlineArrowBack size="20" />
      <span className="ml-1 font-weight-600">{i18n.t('buttons.back')}</span>
    </div>
  );
};

BackLink.propTypes = {};

BackLink.defaultProps = {};

export default BackLink;
