import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Form
} from 'reactstrap';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import v from 'voca';

import ModalService from '../../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../../constants';
import api from '../../../../../../../api';
import {
  createCommunityPhaseQuery,
  updateCommunityPhaseQuery,
} from '../../../../../../../graphql';
import InputError, { isInputInvalid } from '../../../../../../ui/InputError';
import { getError } from '../../../../../../../utils/requestUtils';
import { parseToFloat } from '../../../../../../../utils/parserUtils';
import { stripToEmpty } from '../../../../../../../utils/stringUtils';

const i18nOpts = { scope: 'components.admin.communities.show.sections.phases.form.index' };

function onClose() {
  ModalService.close(ModalNames.PHASE_COMMUNITY_FORM);
}

async function savePhaseCommunity(variables) {
  const query = variables?.input.id ? updateCommunityPhaseQuery : createCommunityPhaseQuery;
  return api.graphql(query, variables)
    .then(({ data }) => Promise.resolve(data));
}

const FormModal = ({ community, opened, params }) => {
  const [form, setForm] = useState({});
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSave = () => {
    const input = getFormInput();

    const variables = { input };
    setSaving(true);
    savePhaseCommunity(variables)
      .then(() => {
        setSaving(false);
        ModalService.close(ModalNames.PHASE_COMMUNITY_FORM);
      }).catch((e) => {
        setSaving(false);

        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      });
  };

  const getFormInput = () => {
    const input = {
      phase: stripToEmpty(form.phase),
      architecturalPrice: parseToFloat(form.architecturalPrice),
      communityId: community.id
    };
    if (form.id) input.id = form.id;

    return input;
  };

  const init = () => {
    const { communityPhase } = params;
    const formData = communityPhase || {};
    setForm(formData);
    setSaving(false);
    setError(null);
  };

  useEffect(() => {
    if (!opened) return;

    init();
  }, [opened]);

  return (
    <Modal isOpen={opened}>
      <ModalHeader>{form.id ? i18n.t('editTitle', i18nOpts) : i18n.t('newTitle', i18nOpts)}</ModalHeader>

      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="phase">{i18n.t('phase', i18nOpts)}</Label>
            <Input
              type="text"
              name="phase"
              id="phase"
              value={form.phase || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'phase')}
            />
            <InputError name="phase" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="architecturalPrice">{i18n.t('architecturalPrice', i18nOpts)}</Label>
            <Input
              type="number"
              name="architecturalPrice"
              id="architecturalPrice"
              value={form.architecturalPrice || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'architecturalPrice')}
            />
            <InputError name="architecturalPrice" error={error} />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="mr-3" onClick={onSave} disabled={saving}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" onClick={onClose} disabled={saving}>
          {i18n.t('buttons.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  opened: store.modals[ModalNames.PHASE_COMMUNITY_FORM]?.opened || false,
  params: store.modals[ModalNames.PHASE_COMMUNITY_FORM]?.params || {},
  community: store.communities.community
}), {})(FormModal);
