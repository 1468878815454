import React from 'react';
import i18n from 'i18n-js';

import ListItem from '../../../admin/Units/List/components/GridView/ListItem';
import './styles.scss';

const i18nOpts = { scope: 'components.common.designer.tooltip.index' };

const Tooltip = ({
  object, positionX, positionY, onMouseLeave
}) => {
  const unit = object?.unit || null;
  return (
    <>
      <div className="svg-tooltip" onMouseLeave={onMouseLeave}>
        { unit ? (
          <ListItem unit={unit} />
        ) : i18n.t('noUnitAssigned', i18nOpts)}
      </div>
      <style dangerouslySetInnerHTML={{
        __html: `
          .svg-tooltip {
            left: ${positionY + 10}px;
            top: ${positionX + 30}px;
            display: block;
            z-index: 1001;
          }
        `
      }}
      />
    </>
  );
};

export default Tooltip;
