import React, { useEffect, useRef } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { connect } from 'react-redux';

import styles from './DescriptionPopover.module.scss';
import { ModalNames } from '../../../../constants';
import ModalService from '../../../../services/ModalService';

function closePopover() {
  ModalService.close(ModalNames.DESCRIPTION_POPOVER);
}

const DescriptionPopover = ({ opened, params }) => {
  const containerRef = useRef(null);
  const { target = 'truncate-description', description } = params;

  const handleClickInDOM = (event) => {
    if (!containerRef.current) return;

    const isClickInsideContainer = containerRef.current.contains(event.target);
    if (!isClickInsideContainer) closePopover();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickInDOM);
    return () => {
      document.removeEventListener('mousedown', handleClickInDOM);
    };
  }, []);

  if (!opened) return null;

  return (
    <Popover
      placement="bottom"
      isOpen={opened}
      target={target}
      popperClassName={styles.popover}
      innerRef={containerRef}
    >
      <PopoverBody className="white-space-pre-line">
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </PopoverBody>
    </Popover>
  );
};

DescriptionPopover.propTypes = {};

DescriptionPopover.defaultProps = {};

export default connect((store) => ({
  opened: store.modals[ModalNames.DESCRIPTION_POPOVER]?.opened || false,
  params: store.modals[ModalNames.DESCRIPTION_POPOVER]?.params || {},
}))(DescriptionPopover);
