import React from 'react';
import { connect } from 'react-redux';
import v from 'voca';

import SavedHomes from './SavedHomes';
import useIsReserveNowEnabled from '../../../../../hooks/useIsReserveNowEnabled';
import StartedReservationFlow from './StartedReservationFlow';

const Followers = ({ unit }) => {
  if (!unit) return null;

  const { observers: followers = [] } = unit;
  const isReserveNowEnabled = useIsReserveNowEnabled();

  const savedHomes = [...followers];
  const reservationStarted = followers.filter((item) => !v.isBlank(item.reservationStartedAt));

  return (
    <div>
      <SavedHomes followers={savedHomes} />

      {isReserveNowEnabled && (
        <StartedReservationFlow followers={reservationStarted} />
      )}
    </div>
  );
};

Followers.propTypes = {};

Followers.defaultProps = {};

export default connect((store) => ({
  unit: store.units.unit
}), {})(Followers);
