export const getCellColor = (percentageChange) => {
  if (!percentageChange) return '';

  if (percentageChange >= 20) {
    return 'bg-success-200';
  }

  if (percentageChange <= -20) {
    return 'bg-danger-200';
  }

  return '';
};
