import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import v from 'voca';

import { isMasterAdmin, isTecSupport } from '../../../../utils/authUtils';
import SocialMediaLoginType from '../../../admin/Customers/List/SocialMediaLoginType';
import CustomerCoBuyersCountBadge from '../CustomerCoBuyersCountBadge';
import CustomerFormSubmissionIndicator from '../CustomerFormSubmissionIndicator';
import { getValidCustomerCompaniesCount } from '../../../admin/Customers/utils';
import OwnlyLogo from '../../../../assets/icons/OwnlyLogo';

const CustomerName = ({ customer, currentCompany }) => {
  const isSuperAdmin = isMasterAdmin() || isTecSupport();

  return (
    <>
      <div className="d-flex align-items-start gap-2">
        <span className="font-weight-600">{customer.name}</span>
        <SocialMediaLoginType customer={customer} />
        <CustomerCoBuyersCountBadge count={customer.coBuyersCount} />
        <CustomerFormSubmissionIndicator customer={customer} />
        {getValidCustomerCompaniesCount(customer, currentCompany.id) > 0 && isSuperAdmin && (
          <span>
            <OwnlyLogo />
          </span>
        )}
      </div>

      {!v.isBlank(customer.email) && (
        <span>{customer.email}</span>
      )}
    </>
  );
};

CustomerName.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

CustomerName.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(CustomerName);
