import React from 'react';
import { connect } from 'react-redux';

import { SalableTypes } from '../../../../../../constants';
import SellersManagement from '../../../../../global/SellersManagement';

const SalesRepresentatives = ({ project }) => (
  <div>
    <SellersManagement salableId={project.id} salableType={SalableTypes.PROJECT} />
  </div>
);

SalesRepresentatives.propTypes = {};

SalesRepresentatives.defaultProps = {};

export default connect((store) => ({
  project: store.projects.project,
}))(SalesRepresentatives);
