import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import i18n from 'i18n-js';
import classNames from 'classnames';

import './styles.scss';
import { Tabs } from '../utils';

const i18nOpts = { scope: 'components.admin.integrations.embedWidget.buildHomeSetup.navTabs.index' };

const NavTabs = ({ activeTab, onChange }) => (
  <Nav vertical id="build-home-nav">
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.INSTALLATION })}
        onClick={() => onChange(Tabs.INSTALLATION)}
      >
        {i18n.t('installation', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.BUILD_HOME })}
        onClick={() => onChange(Tabs.BUILD_HOME)}
      >
        {i18n.t('buildHome', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.SAVE_HOME })}
        onClick={() => onChange(Tabs.SAVE_HOME)}
      >
        {i18n.t('saveHome', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.RESERVE_HOME })}
        onClick={() => onChange(Tabs.RESERVE_HOME)}
      >
        {i18n.t('reserveHome', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.MY_HOMES })}
        onClick={() => onChange(Tabs.MY_HOMES)}
      >
        {i18n.t('myHomes', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.QUICK_POSSESSIONS })}
        onClick={() => onChange(Tabs.QUICK_POSSESSIONS)}
      >
        {i18n.t('quickPossessions', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.JAVASCRIPT_SDK })}
        onClick={() => onChange(Tabs.JAVASCRIPT_SDK)}
      >
        {i18n.t('javaScriptSdk', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.GET_VERIFIED })}
        onClick={() => onChange(Tabs.GET_VERIFIED)}
      >
        {i18n.t('getVerified', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        active={activeTab === Tabs.GET_RENTAL_VERIFIED}
        onClick={() => onChange(Tabs.GET_RENTAL_VERIFIED)}
      >
        {i18n.t('getRentalVerified', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        active={activeTab === Tabs.GATED_POP_UP}
        onClick={() => onChange(Tabs.GATED_POP_UP)}
      >
        {i18n.t('gatedPopUp', i18nOpts)}
      </NavLink>
    </NavItem>
  </Nav>
);

NavTabs.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(Tabs)).isRequired,
  onChange: PropTypes.func,
};

NavTabs.defaultProps = {
  onChange: () => {}
};

export default NavTabs;
