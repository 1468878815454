import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import _ from 'lodash';

import { fetchBuildingModelsBathroomsAsync } from './utils';
import CollapsibleFilterGroup from '../../../../../../common/CollapsibleFilterGroup';
import ShowMoreContent from '../../../../../../common/ShowMoreContent';
import ListValue from '../../../../../../global/filters/components/ListValue';

const i18nOpts = { scope: 'components.admin.units.list.components.filtersModal.bathsFilter.index' };

const BathsFilter = ({ filters, onChange, currentCompany }) => {
  const bathrooms = filters.bathrooms || [];
  const [availableBathrooms, setAvailableBathrooms] = useState([]);
  const [loading, setLoading] = useState(false);

  const onToggle = (event) => {
    const { value } = event.target;
    onChange({ bathrooms: _.xor(bathrooms, [Number(value)]) });
  };

  const loadBuildingModelsBathrooms = () => {
    fetchBuildingModelsBathroomsAsync(currentCompany.id)
      .then((result) => {
        const uniqSortedBathrooms = [...new Set(result)].sort();
        setAvailableBathrooms(uniqSortedBathrooms);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadBuildingModelsBathrooms();
  }, []);

  const filtersCount = useMemo(() => bathrooms.length, [filters]);

  if (loading || !availableBathrooms.length) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <ShowMoreContent
        items={availableBathrooms}
        renderItem={(bathroom) => (
          <ListValue
            key={`bathroom-${bathroom}`}
            label={i18n.t('baths', { ...i18nOpts, count: bathroom })}
            value={bathroom}
            selectedValues={bathrooms}
            onChange={onToggle}
          />
        )}
        showMoreButtonExtraClass="mt-2"
      />
    </CollapsibleFilterGroup>
  );
};

BathsFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

BathsFilter.defaultProps = {
  onChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
}))(BathsFilter);
