import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form, FormGroup, Label, Input, Row, Col, Button, Spinner
} from 'reactstrap';
import { navigate } from 'gatsby';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import v from 'voca';

import { saveCommunity, getCommunity, deleteCommunity } from '../../../../store/actions/communityActions';
import { getLocalImageUrl } from '../../../../utils/imageUtils';
import { getParam } from '../../../../utils/paramsUtils';
import { hasFormValues } from '../../../../utils/formUtils';
import { parseBoolean } from '../../../../utils/parserUtils';
import ImageViewer from '../../../common/ImageViewer';
import DeleteButton from '../../../global/DeleteButton';
import { stripToEmpty, stripToNull } from '../../../../utils/stringUtils';
import { getError } from '../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../ui/InputError';
import api from '../../../../api';
import LotMapSettings from './LotMapSettings';
import MailingLists from './MailingLists';
import SalesRepresentative from './SalesRepresentative';
import { cloneCommunityQuery } from '../../../../graphql';
import confirmClone from '../../../common/ConfirmClone';

const i18nOpts = { scope: 'components.admin.communities.form.index' };

const CommunityStatuses = Object.freeze([
  { value: 'DRAFT', label: i18n.t('statuses.draft') },
  { value: 'PUBLISHED', label: i18n.t('statuses.published') },
]);

function fetchCitiesAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListCities($filter: CityFilterInput) {
      items: listCities(filter: $filter) {
        id
        name
      }
    }
  `, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

function onCancel() {
  navigate('/admin/communities');
}

function cloneCommunity(communityId) {
  const variables = { id: communityId };
  return api.graphql(cloneCommunityQuery, variables)
    .then(({ data: { item } }) => item);
}

class CommunityForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      form: {},
      image: undefined,
      saving: false,
      error: null,
      cities: [],
      cloning: false
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
    this.clone = this.clone.bind(this);
  }

  componentDidMount() {
    this.loadCities();

    const id = parseInt(getParam('id'), 10);
    this.setState({ id }, this.loadCommunity);
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form[name] = value;
    this.setState({ form });
  }

  onImageChange(event) {
    const { name } = event.target;
    const image = event.target.files[0];
    this.setState({ [name]: image });
  }

  onDeleteImage(imageType) {
    this.setState({ [imageType]: null });
  }

  onSave() {
    const { props } = this;
    const { currentCompany } = this.props;
    const { form, image } = this.state;
    const { websiteUrl } = form;

    delete form.imageUrl;

    const input = {
      ...form,
      name: stripToEmpty(form.name),
      cityId: parseInt(form.cityId, 10) || 0,
      websiteUrl: stripToNull(websiteUrl),
      enableNavigation: this.isNew() ? true : parseBoolean(form.enableNavigation),
      enableMakeOffer: parseBoolean(form.enableMakeOffer),
      enableLotMapIntegration: parseBoolean(form.enableLotMapIntegration)
    };

    if (this.isNew()) input.companyId = currentCompany.id;

    this.setState({ saving: true });

    const variables = { input, image };
    props.saveCommunity(variables)
      .then(({ value: { data: { item } } }) => {
        navigate(`/admin/communities/${item.id}`);
      })
      .catch((e) => {
        const error = getError(e);
        if (v.isString(error)) toast.error(error);
        this.setState({ saving: false, error });
      });
  }

  onDelete() {
    const { props } = this;
    const { community } = this.props;

    props.deleteCommunity(community.id)
      .then(() => {
        navigate('/admin/communities');
      });
  }

  getPreviewImageUrls() {
    const { community } = this.props;
    const { image } = this.state;
    const imageUrl = image === null ? null : (getLocalImageUrl(image) || community?.imageUrl);

    return imageUrl;
  }

  clone() {
    const { id } = this.state;
    this.setState({ cloning: true });

    cloneCommunity(id)
      .then((item) => {
        navigate(`/admin/communities/${item.id}`);
      })
      .finally(() => {
        this.setState({ cloning: false });
      });
  }

  loadCommunity() {
    const { props } = this;
    const { id } = this.state;

    if (this.isNew()) return;

    props.getCommunity(id)
      .then(() => {
        const { community } = this.props;

        if (community) {
          const {
            name, city, subRegion, address, description, websiteUrl,
            imageUrl, status, enableNavigation, enableMakeOffer, enableLotMapIntegration,
            lotMapSettings, sendGridContactLists, salesContactName, salesAddress, hubSpotValue
          } = community;
          const form = {
            id,
            name,
            cityId: city?.id,
            subRegion,
            address,
            description,
            websiteUrl,
            imageUrl,
            status,
            enableNavigation,
            enableMakeOffer,
            enableLotMapIntegration,
            lotMapSettings,
            sendGridContactLists,
            salesContactName,
            salesAddress,
            hubSpotValue
          };
          this.setState({ form });
        } else navigate('/admin/communities');
      })
      .catch(() => navigate('/admin/communities'));
  }

  loadCities() {
    const { currentCompany } = this.props;

    fetchCitiesAsync(currentCompany.id)
      .then((cities) => {
        this.setState({ cities });
      })
      .catch(() => {});
  }

  hasFormValues() {
    const { form, image } = this.state;
    const hasValues = hasFormValues(form);

    return hasValues || !!image;
  }

  isNew() {
    const { id } = this.state;
    return !id;
  }

  render() {
    const {
      form, saving, error, cities, cloning
    } = this.state;

    const isNew = this.isNew();
    const hasValues = this.hasFormValues();

    const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

    const imageUrl = this.getPreviewImageUrls();

    return (
      <div>
        <Helmet title={title} />

        <h2 className="mb-4">{title}</h2>

        <Form>
          <Row className="mb-4">
            <Col xs="12" sm="12" md="6" lg="4">
              <FormGroup>
                <Label for="name">{i18n.t('name', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={form.name || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'name')}
                />
                <InputError name="name" error={error} />
              </FormGroup>

              <FormGroup>
                <Label for="cityId">{i18n.t('cityId', i18nOpts)}</Label>
                <Input
                  type="select"
                  name="cityId"
                  id="cityId"
                  value={form.cityId || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'cityId')}
                >
                  <option value="">{i18n.t('select.select')}</option>
                  {
                    cities.map((city) => (
                      <option key={`city-option-${city.id}`} value={city.id}>
                        {city.name}
                      </option>
                    ))
                  }
                </Input>
                <InputError error={error} name="cityId" />
              </FormGroup>

              <FormGroup>
                <Label for="subRegion">{i18n.t('subRegion', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="subRegion"
                  id="subRegion"
                  value={form.subRegion || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'subRegion')}
                />
                <InputError name="subRegion" error={error} />
              </FormGroup>

              <FormGroup>
                <Label for="address">{i18n.t('address', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  value={form.address || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'address')}
                />
                <InputError name="address" error={error} />
              </FormGroup>

              <FormGroup>
                <Label for="image">{i18n.t('image', i18nOpts)}</Label>
                { imageUrl && (
                  <ImageViewer url={imageUrl} onDelete={() => this.onDeleteImage('image')} />
                )}
                <Input
                  type="file"
                  name="image"
                  id="image"
                  accept="image/*"
                  onChange={this.onImageChange}
                  invalid={isInputInvalid(error, 'image')}
                />
                <InputError name="image" error={error} />
              </FormGroup>

              <FormGroup>
                <Label for="description">{i18n.t('description', i18nOpts)}</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  value={form.description || ''}
                  onChange={this.onTextChange}
                  rows={5}
                  invalid={isInputInvalid(error, 'description')}
                />
                <InputError name="description" error={error} />
              </FormGroup>

              <FormGroup>
                <Label for="websiteUrl">{i18n.t('websiteUrl', i18nOpts)}</Label>
                <Input
                  type="url"
                  name="websiteUrl"
                  id="websiteUrl"
                  value={form.websiteUrl || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'websiteUrl')}
                />
                <InputError name="websiteUrl" error={error} />
              </FormGroup>

              {!this.isNew() && (
                <FormGroup check className="mb-3">
                  <Label check>
                    <Input
                      type="checkbox"
                      name="enableNavigation"
                      id="enableNavigation"
                      value={!parseBoolean(form.enableNavigation)}
                      checked={parseBoolean(form.enableNavigation)}
                      onChange={this.onTextChange}
                    />
                    {i18n.t('enableNavigation', i18nOpts)}
                  </Label>
                </FormGroup>
              )}

              <FormGroup check className="mb-3">
                <Label check>
                  <Input
                    type="checkbox"
                    name="enableMakeOffer"
                    id="enableMakeOffer"
                    value={!parseBoolean(form.enableMakeOffer)}
                    checked={parseBoolean(form.enableMakeOffer)}
                    onChange={this.onTextChange}
                  />
                  {i18n.t('enableMakeOffer', i18nOpts)}
                </Label>
              </FormGroup>
            </Col>
            <Col xs="12" sm="12" md="6" lg={{ offset: 2, size: 4 }}>
              <FormGroup>
                <Label for="status">{i18n.t('status', i18nOpts)}</Label>
                <Input
                  type="select"
                  name="status"
                  id="status"
                  value={form.status || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'status')}
                >
                  {
                    CommunityStatuses.map((s) => (
                      <option key={`status-option-${s.value}`} value={s.value}>
                        {s.label}
                      </option>
                    ))
                  }
                </Input>
                <InputError name="status" error={error} />
              </FormGroup>

              <LotMapSettings form={form} onTextChange={this.onTextChange} error={error} />

              <MailingLists form={form} onTextChange={this.onTextChange} />

              <SalesRepresentative form={form} onTextChange={this.onTextChange} error={error} />

              <FormGroup>
                <Label for="hubSpotValue">{i18n.t('hubSpotValue', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="hubSpotValue"
                  id="hubSpotValue"
                  value={form.hubSpotValue || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'hubSpotValue')}
                />
                <InputError name="hubSpotValue" error={error} />
              </FormGroup>
            </Col>
          </Row>

          <Button color="primary" onClick={this.onSave} className="mr-3" disabled={saving || !hasValues}>
            {saving && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.save')}
          </Button>
          <Button outline color="secondary" onClick={onCancel} className="mr-3">
            {i18n.t('buttons.cancel')}
          </Button>
          {!isNew && (
            <>
              <DeleteButton onDelete={this.onDelete} className="mr-3" />

              <Button
                outline
                color="secondary"
                onClick={
                  () => confirmClone(
                    i18n.t('clone.title', { ...i18nOpts, communityName: form.name }),
                    i18n.t('clone.message', i18nOpts),
                    this.clone
                  )
                }
                disabled={cloning}
              >
                {cloning && (<Spinner size="sm" className="mr-2" />)}
                {i18n.t('buttons.clone')}
              </Button>
            </>
          )}
        </Form>
      </div>
    );
  }
}

export default connect((store) => ({
  community: store.communities.community,
  currentCompany: store.companies.currentCompany
}), {
  saveCommunity,
  getCommunity,
  deleteCommunity
})(CommunityForm);
