import { TaxRateTypes } from '../constants';

const types = {
  [TaxRateTypes.CITY_BASED]: 'City Based',
  [TaxRateTypes.COMMUNITY_PROJECT_BASED]: 'Community/Project Based',
};

export function getSanitizedType(type) {
  return types[type];
}

export function getArrayMappedNames(communities) {
  return communities.map((community) => community.name).join(', ');
}

export function getTaxRatePercentage(taxRate) {
  return `${Math.round(taxRate * 100)}%`;
}

export const calculateRate = (numerator, denominator) => {
  if (!denominator || denominator === 0) return 0;

  const rate = (numerator / denominator) * 100;
  if (Number.isInteger(rate)) return Math.round(rate);

  return Number(rate.toFixed(2));
};

export const displayRatePercentage = (rate) => `(${rate}%)`;
