import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import {
  Button, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { connect } from 'react-redux';

import { setViewMode, setQuickPossessionColumns } from '../../../../store/actions/quickPossessionActions';
import { ModalNames, ViewModes } from '../../../../constants';
import ModalService from '../../../../services/ModalService';
import QuickPossessionFormModal from '../components/QuickPossessionFormModal';
import SearchBar from '../../../common/SearchBar';
import Loading from '../../../ui/Loading';
import ViewModesDropdown from '../../../global/ViewModesDropdown';
import EditColumnsButton from './components/EditColumnsButton';
import QuickPossessionColumnsPickerModal from './components/QuickPossessionColumnsPickerModal';
import FiltersButton from './components/FiltersButton';
import QuickPossessionFiltersModal from './components/QuickPossessionFiltersModal';
import { Tabs } from './utils';
import ActiveListings from './ActiveListings';
import InactiveListings from './InactiveListings';

const i18nOpts = { scope: 'components.admin.quickPossessions.list.index' };

const List = ({ viewMode, currentUser, ...props }) => {
  const [activeTab, setActiveTab] = useState(Tabs.ACTIVE_LISTINGS);

  const [mounted, setMounted] = useState(false);
  const [query, setQuery] = useState(null);
  const [initialColumnsSet, setInitialColumnsSet] = useState(false);

  const onAdd = () => {
    ModalService.open(ModalNames.QUICK_POSSESSION_FORM);
  };

  const onSearch = (e, q) => {
    e.preventDefault();

    setQuery(q);
  };

  const onChangeViewMode = (newMode) => {
    props.setViewMode(newMode);
  };

  const setInitialColumns = () => {
    const { quickPossessions: initialColumns = [] } = currentUser?.columnSettings || {};
    if (initialColumns && initialColumns.length) props.setQuickPossessionColumns(initialColumns);
    setInitialColumnsSet(true);
  };

  useEffect(() => {
    if (initialColumnsSet) setMounted(true);
  }, [initialColumnsSet]);

  useEffect(() => {
    setInitialColumns();
  }, []);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="mb-4">
        <div className="font-24">{i18n.t('title', i18nOpts)}</div>
      </div>

      <div className="d-flex mb-4 align-items-center flex-wrap gap-3">
        <div className="flex-grow-1">
          <SearchBar
            onSubmit={onSearch}
            inputProps={{ bsSize: 'md', placeholder: i18n.t('searchBarPlaceholder', i18nOpts) }}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center flex-wrap gap-3">
          <ViewModesDropdown viewMode={viewMode} onChange={onChangeViewMode} />

          {viewMode === ViewModes.LIST && <EditColumnsButton />}

          <FiltersButton />

          <Button color="primary" onClick={onAdd}>
            <i className="fas fa-plus mr-2" />
            {i18n.t('buttons.add', i18nOpts)}
          </Button>
        </div>
      </div>

      <Nav tabs>
        <NavItem>
          <NavLink
            active={activeTab === Tabs.ACTIVE_LISTINGS}
            onClick={() => setActiveTab(Tabs.ACTIVE_LISTINGS)}
          >
            {i18n.t('tabs.activeListings', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === Tabs.INACTIVE_LISTINGS}
            onClick={() => setActiveTab(Tabs.INACTIVE_LISTINGS)}
          >
            {i18n.t('tabs.inactiveListings', i18nOpts)}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.ACTIVE_LISTINGS}>
          <ActiveListings query={query} initialColumnsSet={initialColumnsSet} />
        </TabPane>
        <TabPane tabId={Tabs.INACTIVE_LISTINGS}>
          <InactiveListings query={query} initialColumnsSet={initialColumnsSet} />
        </TabPane>
      </TabContent>

      <QuickPossessionFormModal />
      <QuickPossessionColumnsPickerModal />
      <QuickPossessionFiltersModal />
    </div>
  );
};

export default connect((store) => ({
  viewMode: store.quickPossessions.viewMode,
  currentUser: store.users.currentUser
}), {
  setViewMode,
  setQuickPossessionColumns
})(List);
