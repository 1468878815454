import moment from 'moment/moment';

import api from '../../../../api';
import { getLeadGenerationQuery } from '../../../../graphql';

function fakeValue() {
  return Math.round(Math.random() * 100);
}

function generateSampleDataAsync() {
  const leadGeneration = [];
  let months = 6;
  let currentDate = moment();
  while (months > 0) {
    const dateDivision = currentDate.format('MMM YYYY');

    leadGeneration.push({
      dateDivision,
      capturedLeads: {
        ownlyLeadsCount: fakeValue(),
        webtrackerLeadsCount: fakeValue()
      }
    });

    months -= 1;
    currentDate = currentDate.subtract(1, 'months');
  }
  return Promise.resolve(leadGeneration.reverse());
}

function fetchLeadGenerationAsync(filter) {
  const variables = {
    reportFilter: {
      ...filter
    }
  };

  return api.graphql(getLeadGenerationQuery, variables)
    .then(({ data: { results } }) => results);
}

export function fetchLeadGenerationDataAsync(filter, company) {
  return company.enableSampleData
    ? generateSampleDataAsync()
    : fetchLeadGenerationAsync(filter);
}
