import React from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, CardImg, CardFooter, Row, Col
} from 'reactstrap';
import { BiBuildingHouse } from 'react-icons/bi';
import v from 'voca';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { MdLocationOn } from 'react-icons/md';

import './styles.scss';
import { formatCurrency, isValidAmount } from '../../../../../../utils/currencyUtils';
import { DEFAULT_IMAGE } from '../../../../../../constants';
import { deleteModel } from '../../../../../../store/actions/modelActions';
import StatusIndicator from '../../../../../global/StatusIndicator';

const i18nOpts = { scope: 'components.admin.models.list.gridView.listItem.index' };

const ListItem = ({ model, onHoverModelCommunities }) => {
  if (!model) return null;

  const communitiesStringified = JSON.stringify(
    model.communities.map((c) => ({ id: c.id, name: c.name }))
  );

  const hasModelCommunities = !!model.communities.length;
  const { basePrice } = model;

  return (
    <Card className="model-card">
      <Link to={`/admin/models/${model.id}`}>
        <CardImg top width="100%" src={model.defaultImageUrl || DEFAULT_IMAGE} alt="" className="mb-0 model-image" />
      </Link>
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1 pr-3">
            <h3>
              <Link to={`/admin/models/${model.id}`}>{model.name}</Link>
            </h3>
          </div>
          <div className="icons-container d-inline-flex">
            { hasModelCommunities && (
              <div className="col-primary-1 mr-2" onMouseEnter={onHoverModelCommunities}>
                <a data-for="model-communities-tooltip" data-tip={communitiesStringified}>
                  <MdLocationOn size="1.4rem" />
                </a>
              </div>
            )}
            <StatusIndicator status={model.status} id={model.id} />
          </div>
        </div>

        <Row>
          <Col md="6">
            {!v.isBlank(model.sizeSqft) && (
              <div className="text-uppercase">
                {i18n.t('sizeSqft', { ...i18nOpts, size: model.sizeSqft })}
              </div>
            )}
            {isValidAmount(basePrice) && (
              <div className="mt-2">
                <div className="text-uppercase font-bold font-12">
                  {i18n.t('from', { ...i18nOpts, price: formatCurrency(basePrice) })}
                </div>
                <div className="font-10 text-uppercase text-muted">
                  {i18n.t('notIncludesTaxes', i18nOpts)}
                </div>
              </div>
            )}
          </Col>
          <Col md="6">
            {model.productType && (
              <div className="text-uppercase">
                <BiBuildingHouse />
                <span className="ml-1">{model.productType.name}</span>
              </div>
            )}
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="d-flex bg-white justify-content-around">
        <div>
          <span className="mr-2">{model.beds || 0}</span>
          <i className="fas fa-bed" />
        </div>
        <div>
          <span className="mr-2">{model.baths || 0}</span>
          <i className="fas fa-bath" />
        </div>
      </CardFooter>
    </Card>
  );
};

ListItem.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect(() => ({}), {
  deleteModel
})(ListItem);
