import React, { useMemo, useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { FaChevronDown, FaChevronRight } from 'react-icons/all';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import SecureStore from '../../../../../utils/secureStore';
import { formatNumber, isValidAmount } from '../../../../../utils/currencyUtils';
import { getCellColor } from './utils';
import { calculateRate, displayRatePercentage } from '../../../../../utils/taxRateUtils';

const i18nOpts = { scope: 'components.admin.masterAdminDashboard.visitorLeadsTable.listItem.index' };

const useRateCalculations = (item) => useMemo(() => ({
  returningVisitorsRate: calculateRate(
    item.totalReturningVisitors.currentValue,
    item.totalVisitors.currentValue
  ),
  previousReturningVisitorsRate: calculateRate(
    item.totalReturningVisitors.previousValue,
    item.totalVisitors.previousValue
  ),
  websiteReturningVisitorsRate: calculateRate(
    item.websiteReturningVisitors.currentValue,
    item.websiteVisitors.currentValue
  ),
  previousWebsiteReturningVisitorsRate: calculateRate(
    item.websiteReturningVisitors.previousValue,
    item.websiteVisitors.previousValue
  ),
  ownlyReturningVisitorsRate: calculateRate(
    item.ownlyReturningVisitors.currentValue,
    item.ownlyVisitors.currentValue
  ),
  previousOwnlyReturningVisitorsRate: calculateRate(
    item.ownlyReturningVisitors.previousValue,
    item.ownlyVisitors.previousValue
  ),
  newLeadsRate: calculateRate(
    item.newLeads.currentValue,
    item.totalVisitors.currentValue
  ),
  previousNewLeadsRate: calculateRate(
    item.newLeads.previousValue,
    item.totalVisitors.previousValue
  ),
  websiteNewLeadsRate: calculateRate(
    item.websiteNewLeads.currentValue,
    item.websiteVisitors.currentValue
  ),
  previousWebsiteNewLeadsRate: calculateRate(
    item.websiteNewLeads.previousValue,
    item.websiteVisitors.previousValue
  ),
  ownlyNewLeadsRate: calculateRate(
    item.ownlyNewLeads.currentValue,
    item.ownlyVisitors.currentValue
  ),
  previousOwnlyNewLeadsRate: calculateRate(
    item.ownlyNewLeads.previousValue,
    item.ownlyVisitors.previousValue
  ),
  reservationCompletedRate: calculateRate(
    item.reservationCompleted.currentValue,
    item.reservationStarted.currentValue
  ),
  previousReservationCompletedRate: calculateRate(
    item.reservationCompleted.previousValue,
    item.reservationStarted.previousValue
  ),
  verificationCompletedRate: calculateRate(
    item.verificationCompleted.currentValue,
    item.verificationStarted.currentValue
  ),
  previousVerificationCompletedRate: calculateRate(
    item.verificationCompleted.previousValue,
    item.verificationStarted.previousValue
  )
}), [item]);

const MetricCell = ({
  containerId, metric, rate, previousRate
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const popoverId = `popover-${containerId}`;

  return (
    <>
      <td
        className={getCellColor(metric.percentageChange)}
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
        id={popoverId}
      >
        {formatNumber(metric.currentValue)}
        {isValidAmount(rate) && (
          <span className="ml-1">{displayRatePercentage(rate)}</span>
        )}
      </td>
      <Popover target={popoverId} placement="bottom" isOpen={showPopover}>
        <PopoverBody>
          {metric.previousValue === null ? i18n.t('noPreviousData', i18nOpts) : (
            <>
              {i18n.t('previous', i18nOpts)}
              {formatNumber(metric.previousValue)}
              {isValidAmount(previousRate) && (
                <span className="ml-1">{displayRatePercentage(previousRate)}</span>
              )}
            </>
          )}
        </PopoverBody>
      </Popover>
    </>
  );
};

const ListItem = ({ item }) => {
  const [expanded, setExpanded] = useState(false);
  const rates = useRateCalculations(item);
  const itemId = `${item.company.id}`;

  const onCompanyClick = (company) => {
    SecureStore.setCurrentCompanyId(company.id);
    window.location = '/admin/overview';
  };

  const onToggle = () => setExpanded(!expanded);

  return (
    <>
      <tr className="text-center">
        <td className="text-left text-nowrap">
          {expanded ? (
            <FaChevronDown role="button" aria-hidden="true" className="col-gray-500" onClick={onToggle} />
          ) : (
            <FaChevronRight role="button" aria-hidden="true" className="col-gray-500" onClick={onToggle} />
          )}
          <div
            role="button"
            aria-hidden
            className="col-primary-1 text-decoration-underline d-inline-block ml-2"
            onClick={() => onCompanyClick(item.company)}
          >
            {item.company.name}
          </div>
        </td>
        <MetricCell
          containerId={`${itemId}-total-visitors`}
          metric={item.totalVisitors}
        />
        <MetricCell
          containerId={`${itemId}-total-returning-visitors`}
          metric={item.totalReturningVisitors}
          rate={rates.returningVisitorsRate}
          previousRate={rates.previousReturningVisitorsRate}
        />
        <MetricCell
          containerId={`${itemId}-total-new-leads`}
          metric={item.newLeads}
          rate={rates.newLeadsRate}
          previousRate={rates.previousNewLeadsRate}
        />
        <MetricCell
          containerId={`${itemId}-total-reservation-started`}
          metric={item.reservationStarted}
        />
        <MetricCell
          containerId={`${itemId}-total-reservation-completed`}
          metric={item.reservationCompleted}
          rate={rates.reservationCompletedRate}
          previousRate={rates.previousReservationCompletedRate}
        />
        <MetricCell
          containerId={`${itemId}-total-verification-started`}
          metric={item.verificationStarted}
        />
        <MetricCell
          containerId={`${itemId}-total-verification-completed`}
          metric={item.verificationCompleted}
          rate={rates.verificationCompletedRate}
          previousRate={rates.previousVerificationCompletedRate}
        />
      </tr>

      {expanded && (
        <>
          <tr className="text-center bg-gray-50">
            <td className="text-right font-weight-500">{i18n.t('website', i18nOpts)}</td>
            <MetricCell
              containerId={`${itemId}-website-visitors`}
              metric={item.websiteVisitors}
            />
            <MetricCell
              containerId={`${itemId}-website-returning-visitors`}
              metric={item.websiteReturningVisitors}
              rate={rates.websiteReturningVisitorsRate}
              previousRate={rates.previousWebsiteReturningVisitorsRate}
            />
            <MetricCell
              containerId={`${itemId}-website-new-leads`}
              metric={item.websiteNewLeads}
              rate={rates.websiteNewLeadsRate}
              previousRate={rates.previousWebsiteNewLeadsRate}
            />
            <td />
            <td />
            <td />
            <td />
          </tr>
          <tr className="text-center bg-gray-50">
            <td className="text-right font-weight-500">{i18n.t('ownly', i18nOpts)}</td>
            <MetricCell
              containerId={`${itemId}-ownly-visitors`}
              metric={item.ownlyVisitors}
            />
            <MetricCell
              containerId={`${itemId}-ownly-returning-visitors`}
              metric={item.ownlyReturningVisitors}
              rate={rates.ownlyReturningVisitorsRate}
              previousRate={rates.previousOwnlyReturningVisitorsRate}
            />
            <MetricCell
              containerId={`${itemId}-ownly-new-leads`}
              metric={item.ownlyNewLeads}
              rate={rates.ownlyNewLeadsRate}
              previousRate={rates.previousOwnlyNewLeadsRate}
            />
            <td />
            <td />
            <td />
            <td />
          </tr>
        </>
      )}
    </>
  );
};

ListItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
