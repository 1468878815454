import api from '../../../../api';
import { paginationFragment } from '../../../../graphql/fragments/pagination';
import { companyContactFragment } from '../../../../graphql/fragments/companyContacts';

export const fetchCustomersAsync = (variables = {}) => api.graphql(`
    query ListContacts(
      $filter: ContactFilterInput
      $sort: SortInput
      $page: Int
      $pageSize: Int
      $companyId: Int
      $withAffordability: Boolean! = false
    ) {
      result: listContacts(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
        items {
          id
          name
          email
          socialMediaType(companyId: $companyId)
          coBuyersCount(companyId: $companyId)
          companyContact(companyId: $companyId) {
            ${companyContactFragment}
          }
          companyContacts {
            company {
              id
              name
            }
          }
          activeHome(companyId: $companyId) {
            id
            beds
            baths
            sizeSqft
            singleFamilyHome {
              community {
                id
                name
              }
            }
            multiFamilyHome {
              project {
                id
                name
              }
            }
          }
        }
        pagination {
          ${paginationFragment}
        }
      }
    }
  `, variables)
  .then(({ data: { result } }) => result);
