import moment from 'moment/moment';

import api from '../../../../api';
import { getVisitorTrafficQuery } from '../../../../graphql';

function fakeValue() {
  return Math.round(Math.random() * 100);
}

function generateSampleDataAsync() {
  const visitorTraffic = [];
  let months = 6;
  let currentDate = moment();
  while (months > 0) {
    const dateDivision = currentDate.format('MMM YYYY');

    visitorTraffic.push({
      dateDivision,
      visitors: {
        ownlyVisitorsCount: fakeValue() + fakeValue(),
        widgetVisitorsCount: fakeValue() + fakeValue(),
        webtrackerVisitorsCount: fakeValue() + fakeValue()
      },
      returningVisitors: {
        ownlyVisitorsCount: fakeValue(),
        widgetVisitorsCount: fakeValue(),
        webtrackerVisitorsCount: fakeValue()
      }
    });

    months -= 1;
    currentDate = currentDate.subtract(1, 'months');
  }
  return Promise.resolve(visitorTraffic.reverse());
}

function fetchVisitorTrafficAsync(filter) {
  const variables = {
    reportFilter: {
      ...filter
    }
  };

  return api.graphql(getVisitorTrafficQuery, variables)
    .then(({ data: { results } }) => results);
}

export function fetchVisitorTrafficDataAsync(filter, company) {
  return company.enableSampleData
    ? generateSampleDataAsync()
    : fetchVisitorTrafficAsync(filter);
}
