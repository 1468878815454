import React from 'react';
import {
  Card, CardBody, CardFooter, CardImg, Col, Row
} from 'reactstrap';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
import { FiLayers } from 'react-icons/fi';

import './styles.scss';
import { DEFAULT_IMAGE } from '../../../../../../../constants';
import { formatCurrency, isValidAmount } from '../../../../../../../utils/currencyUtils';

const ListItem = ({ unit }) => {
  if (!unit) return null;
  const imageUrl = unit.defaultImageUrl || DEFAULT_IMAGE;

  return (
    <Card className="unit-card">
      <Link to={`/admin/units/${unit.id}`}>
        <CardImg top src={imageUrl} alt="" className="mb-0 unit-image" />
      </Link>
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1">
            <h3>
              <Link to={`/admin/units/${unit.id}`}>{unit.name}</Link>
            </h3>
          </div>
        </div>

        <Row>
          <Col xs="12">
            <div className="d-flex align-items-center text-uppercase">
              <div><FiLayers /></div>
              <div>
                <Link
                  className="ml-2"
                  to={`/admin/buildingModels/${unit.buildingModel.id}`}
                >
                  {unit.buildingModel.name}
                </Link>
              </div>
            </div>
          </Col>
          { isValidAmount(unit.price) && (
            <Col xs="12" md="6" className="mt-2">
              <div>{formatCurrency(unit.price)}</div>
            </Col>
          )}
        </Row>

      </CardBody>
      <CardFooter className="d-flex bg-white justify-content-around">
        <div>
          <span className="mr-2">{unit.buildingModel.bedrooms || 0}</span>
          <i className="fas fa-bed" />
        </div>
        <div>
          <span className="mr-2">{unit.buildingModel.bathrooms || 0}</span>
          <i className="fas fa-bath" />
        </div>
      </CardFooter>
    </Card>
  );
};

ListItem.propTypes = {
  unit: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
