import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import CustomerInterests from '../../../CustomerInterests';
import {
  getCustomerInterestsFromTrackingEvents
} from '../../../../../../utils/customerUtils';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.visit.interests.index' };

const Interests = ({ activity }) => {
  const { visitorSession } = activity;
  if (!visitorSession) return null;

  const { trackingEvents } = visitorSession;
  if (!trackingEvents.length) return null;

  const interests = getCustomerInterestsFromTrackingEvents(trackingEvents);
  if (!interests.length) return null;

  return (
    <div>
      <div>{i18n.t('title', i18nOpts)}</div>
      <CustomerInterests interests={interests} />
    </div>
  );
};

Interests.propTypes = {
  activity: PropTypes.objectOf(PropTypes.any).isRequired
};

Interests.defaultProps = {};

export default Interests;
