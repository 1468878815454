import React, { useState } from 'react';
import { FiCalendar } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import momentTz from 'moment-timezone';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import { Colors, DateFormats } from '../../../constants';

const DatePicker = ({ initialDate, format, onChange }) => {
  const [selectedDate, setSelectedDate] = useState(initialDate ? momentTz(initialDate).format(format) : '');

  const onDatePicked = (event, picker) => {
    const newSelectedDate = picker.startDate.format(format);
    setSelectedDate(newSelectedDate);
    onChange(newSelectedDate);
  };

  const onClearDate = () => {
    setSelectedDate('');
    onChange('');
  };

  const datePickerOptions = {
    singleDatePicker: true,
    autoApply: true,
    autoUpdateInput: false,
    locale: { format }
  };

  return (
    <div className="position-relative">
      <div className="input-icon-prepend">
        <FiCalendar size={16} color={Colors.GRAY_600} />
      </div>
      <DateRangePicker initialSettings={datePickerOptions} onApply={onDatePicked}>
        <input type="text" className="form-control bg-white" value={selectedDate} readOnly />
      </DateRangePicker>
      {selectedDate && (
        <div className="input-icon-append">
          <MdClose
            size={20}
            role="button"
            aria-hidden
            onClick={onClearDate}
            color={Colors.GRAY_600}
            title={i18n.t('buttons.clear')}
          />
        </div>
      )}
    </div>
  );
};

DatePicker.propTypes = {
  initialDate: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func
};

DatePicker.defaultProps = {
  initialDate: undefined,
  format: DateFormats.MMM_DD_YYYY,
  onChange: () => {}
};

export default DatePicker;
