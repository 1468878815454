import i18n from 'i18n-js';

import { UnitAvailabilities } from '../constants';
import store from '../store';
import { isValidURL } from './urlUtils';

const UnitAvailabilityLabels = {
  [UnitAvailabilities.AVAILABLE]: i18n.t('enums.unitAvailabilities.available'),
  [UnitAvailabilities.PENDING]: i18n.t('enums.unitAvailabilities.pending'),
  [UnitAvailabilities.COMING_SOON]: i18n.t('enums.unitAvailabilities.comingSoon'),
  [UnitAvailabilities.NOT_AVAILABLE]: i18n.t('enums.unitAvailabilities.notAvailable'),
  [UnitAvailabilities.SOLD]: i18n.t('enums.unitAvailabilities.sold')
};

export function getUnitAvailabilityName(availability) {
  return UnitAvailabilityLabels[availability];
}

export function getUnitEmbeddedBaseUrl(unit) {
  const { buildingModel } = unit;
  if (isValidURL(buildingModel?.project?.websiteUrl)) return buildingModel.project.websiteUrl;

  const { currentCompany } = store.getState().companies;
  if (isValidURL(currentCompany.embeddedBaseUrl)) return currentCompany.embeddedBaseUrl;

  return currentCompany.domains[0];
}
