import React from 'react';

import useValidSelectedColumns from '../../../hooks/useValidSelectedColumns';
import { isAdmin } from '../../../../../../../utils/authUtils';
import CustomColumnName from './CustomColumnName';

const ListHeader = () => {
  const columns = useValidSelectedColumns();

  return (
    <tr>
      {
        columns.map((column) => (
          <CustomColumnName key={`unit-header-${column}`} column={column} />
        ))
      }
      {isAdmin() && (
        <th>&nbsp;</th>
      )}
    </tr>
  );
};

ListHeader.propTypes = {};

ListHeader.defaultProps = {};

export default ListHeader;
