import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';

import Loading from '../../../ui/Loading';
import { getProject } from '../../../../store/actions/projectActions';
import NavigationService from '../../../../services/NavigationService';
import { NavigationMainNames } from '../../../../constants';
import TwoColumnLayout from '../../../common/TwoColumnLayout';
import Summary from './Summary';
import Sections from './Sections';
import DescriptionPopover from '../../../common/TruncateDescription/DescriptionPopover';
import DirectLinkModal from './components/DirectLinkModal';
import QRCodeModal from '../../../global/QRCodeModal';

const Show = ({ id, project, ...props }) => {
  const [mounted, setMounted] = useState(false);
  const projectId = parseInt(id, 10);

  const loadProject = () => {
    setMounted(false);

    props.getProject(projectId)
      .then(({ value: { data: { item } } }) => {
        setMounted(true);
        NavigationService.addItem(item, NavigationMainNames.PROJECTS);
      })
      .catch(() => navigate('/admin/projects'));
  };

  useEffect(() => {
    loadProject();
  }, [id]);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={project.name} />

      <TwoColumnLayout
        leftComponent={<Summary />}
        rightComponent={<Sections />}
      />

      <DescriptionPopover />
      <DirectLinkModal />
      <QRCodeModal />
    </div>
  );
};

Show.propTypes = {};

Show.defaultProps = {};

export default connect((store) => ({
  project: store.projects.project,
}), {
  getProject
})(Show);
