import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

import { getQuickPossessionAvailabilityName } from '../../../../../../../../../utils/enumUtils';

const Availability = ({ quickPossession }) => {
  const { availability } = quickPossession;

  if (v.isBlank(availability)) return null;

  return (
    <div>
      <span className={`badge text-white availability-badge ${v.slugify(availability)}`}>
        {getQuickPossessionAvailabilityName(availability)}
      </span>
    </div>
  );
};

Availability.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

Availability.defaultProps = {};

export default Availability;
