import v from 'voca';
import i18n from 'i18n-js';

export function getHomePriceDisplayModeName(displayMode) {
  if (v.isBlank(displayMode)) return displayMode;
  return i18n.t(`enums.homePriceDisplayMode.${v.camelCase(displayMode)}`);
}

export function getFormMappableFieldName(mappableField) {
  if (v.isBlank(mappableField)) return mappableField;
  return i18n.t(`enums.formMappableFields.${v.camelCase(mappableField)}`);
}

export function getCurrencyName(currency) {
  if (v.isBlank(currency)) return currency;
  return i18n.t(`enums.currency.${v.camelCase(currency)}`);
}

export function getFloorOptionAvailabilityRuleName(availabilityRule) {
  if (v.isBlank(availabilityRule)) return availabilityRule;
  return i18n.t(`enums.floorOptionAvailabilityRule.${v.camelCase(availabilityRule)}`);
}

export function getFloorOptionAvailabilityRuleTypeName(availabilityRuleType) {
  if (v.isBlank(availabilityRuleType)) return availabilityRuleType;
  return i18n.t(`enums.floorOptionAvailabilityRuleType.${v.camelCase(availabilityRuleType)}`);
}

export function getContactStageName(contactStage) {
  if (v.isBlank(contactStage)) return contactStage;
  return i18n.t(`enums.contactStage.${v.camelCase(contactStage)}`);
}

export function getTrafficSourceTypeName(trafficSourceType) {
  if (v.isBlank(trafficSourceType)) return trafficSourceType;
  return i18n.t(`enums.trafficSourceType.${v.camelCase(trafficSourceType)}`);
}

export function getSearchEngineName(engineName) {
  if (v.isBlank(engineName)) return engineName;
  return i18n.t(`enums.searchEngine.${v.camelCase(engineName)}`);
}

export function getSocialMediaName(socialMedia) {
  if (v.isBlank(socialMedia)) return socialMedia;
  return i18n.t(`enums.socialMedia.${v.camelCase(socialMedia)}`);
}

export function getIdVerificationProviderName(idVerificationProvider) {
  if (v.isBlank(idVerificationProvider)) return idVerificationProvider;
  return i18n.t(`enums.idVerificationProvider.${v.camelCase(idVerificationProvider)}`);
}

export function getPreApprovalVerificationProviderName(preApprovalVerificationProvider) {
  if (v.isBlank(preApprovalVerificationProvider)) return preApprovalVerificationProvider;
  return i18n.t(`enums.preApprovalVerificationProvider.${v.camelCase(preApprovalVerificationProvider)}`);
}

export function getBankVerificationProviderName(bankVerificationProvider) {
  if (v.isBlank(bankVerificationProvider)) return bankVerificationProvider;
  return i18n.t(`enums.bankVerificationProvider.${v.camelCase(bankVerificationProvider)}`);
}

export function getDefaultHomePageName(defaultHomePage) {
  if (v.isBlank(defaultHomePage)) return defaultHomePage;
  return i18n.t(`enums.defaultHomePage.${v.camelCase(defaultHomePage)}`);
}

export function getCustomerColumnName(customerColumn) {
  if (v.isBlank(customerColumn)) return customerColumn;
  return i18n.t(`enums.customerColumn.${v.camelCase(customerColumn)}`);
}

export function getCustomerStatusName(status) {
  if (v.isBlank(status)) return status;
  return i18n.t(`enums.customerStatus.${v.camelCase(status)}`);
}

export function getCustomerReservationStatusName(reservationStatus) {
  if (v.isBlank(reservationStatus)) return reservationStatus;
  return i18n.t(`enums.customerReservationStatus.${v.camelCase(reservationStatus)}`);
}

export function getCustomerVerificationStatusName(verificationStatus) {
  if (v.isBlank(verificationStatus)) return verificationStatus;
  return i18n.t(`enums.customerVerificationStatus.${v.camelCase(verificationStatus)}`);
}

export function getPaymentMethodName(paymentMethod) {
  if (v.isBlank(paymentMethod)) return paymentMethod;
  return i18n.t(`enums.paymentMethod.${v.camelCase(paymentMethod)}`);
}

export function getSalesInviteMethodName(salesInviteMethod) {
  if (v.isBlank(salesInviteMethod)) return null;
  return i18n.t(`enums.salesInviteMethod.${v.camelCase(salesInviteMethod)}`);
}

export function getRentalWorkflowTypeName(rentalWorkflowType) {
  if (v.isBlank(rentalWorkflowType)) return null;
  return i18n.t(`enums.rentalWorkflowType.${v.camelCase(rentalWorkflowType)}`);
}

export function getVerifiedWorkflowTypeName(verifiedWorkflowType) {
  if (v.isBlank(verifiedWorkflowType)) return null;
  return i18n.t(`enums.verifiedWorkflowType.${v.camelCase(verifiedWorkflowType)}`);
}

export function getTeamUserPermissionName(teamUserPermission) {
  if (v.isBlank(teamUserPermission)) return null;
  return i18n.t(`enums.teamUserPermission.${v.camelCase(teamUserPermission)}`);
}

export function getIdDocumentTypeName(documentType) {
  if (v.isBlank(documentType)) return documentType;
  return i18n.t(`enums.idDocumentType.${v.camelCase(documentType)}`);
}

export function getQuickPossessionAvailabilityName(availability) {
  if (v.isBlank(availability)) return availability;
  return i18n.t(`enums.quickPossessionAvailability.${v.camelCase(availability)}`);
}

export function getQuickPossessionStatusName(status) {
  if (v.isBlank(status)) return status;
  return i18n.t(`enums.quickPossessionStatus.${v.camelCase(status)}`);
}

export function getQuickPossessionStageName(stage) {
  if (v.isBlank(stage)) return stage;
  return i18n.t(`enums.quickPossessionStages.${v.camelCase(stage)}`);
}

export function getQuickPossessionColumnName(quickPossessionColumn) {
  if (v.isBlank(quickPossessionColumn)) return quickPossessionColumn;
  return i18n.t(`enums.quickPossessionColumn.${v.camelCase(quickPossessionColumn)}`);
}

export function getDepositStatusName(status) {
  if (v.isBlank(status)) return status;
  return i18n.t(`enums.depositStatus.${v.camelCase(status)}`);
}

export function getRefundReasonName(reason) {
  if (v.isBlank(reason)) return reason;
  return i18n.t(`enums.refundReasons.${v.camelCase(reason)}`);
}

export function getProjectBuildAndPriceStartingPointName(buildAndPriceStartingPoint) {
  if (v.isBlank(buildAndPriceStartingPoint)) return null;
  return i18n.t(`enums.projectBuildAndPriceStartingPoints.${v.camelCase(buildAndPriceStartingPoint)}`);
}

export function getUnitColumnName(unitColumn) {
  if (v.isBlank(unitColumn)) return unitColumn;
  return i18n.t(`enums.unitColumn.${v.camelCase(unitColumn)}`);
}

export function getUnitStageName(stage) {
  if (v.isBlank(stage)) return stage;
  return i18n.t(`enums.unitStages.${v.camelCase(stage)}`);
}
